//<div id="burger" class="burger clearfix">menu
//	<div class="line1"></div>
//</div>

$outerWidth: 4.368rem;
$outerHeight: 4.368rem;

$lineHeight: .3rem;
$lineWidth: 4.368rem;
$gap: .85rem;
$radius: 0rem;

$outerWidthM: 4.8rem;
$outerHeightM: 4.8rem;

$lineHeightM: .2rem;
$lineWidthM: 1.2rem;
$gapM: .2rem;
$radiusM: .1rem;

$topLineColour: $green;
$topLineColourOpen: $green;

$middleLineColour: $green;
$middleLineColourOpen: transparent;

$bottomLineColour: $green;
$bottomLineColourOpen: $green;

.burger{
	background-color: $grey;
	border: clamp(1px, .1rem, .1rem) solid $black;
	padding: 1.1rem 1.5rem;
	border-radius: 2.9155rem;
	@include font-size(14);
	@include line-height(14,20);
	display: flex;
	align-items: center;
	position: relative;
	perspective: 900000px;
	z-index: 3;
	cursor: pointer;

	.menu-open &{

		i{
			transform: rotateX(180deg);
		}
	}

	@media (min-width: $md + 1){
		display: none;
	}

	@media (max-width: $md){
		margin-left: 1rem;
	}

	i{
		width: 1rem;
		aspect-ratio: 16/10;
		transform: rotateX(0deg);
		background-image: url(../images/menu-arrow.svg);
		background-repeat: no-repeat;
		background-size: 100% auto;
		transition: $time;
		backface-visibility: visible!important;
		margin-right: .3rem;
		margin-left: .8rem;
	}
	
	// height: $outerHeight;
	// width: $outerWidth;
	// border-radius: 0;
	// line-height: $outerHeight;
	// padding: 0;
	// -webkit-perspective: 900000px;
	// perspective: 900000px;
	// z-index: 1;
	// pointer-events: auto;
	// position: relative;
	// transition: $time;
	// font-weight: 400;
	// border-radius: 0;
	// display: none;

	// @media (max-width: $md){
	// 	display: block;
	// 	height: $outerHeightM;
	// 	width: $outerWidthM;
	// 	line-height: $outerHeightM;
	// 	z-index: 3;
	// 	border-radius: 50%;
	// 	background-color: $black;
	// 	box-shadow: inset 0 0 0 .2rem $green;
	// }

	// &:hover{
	// 	cursor: pointer;
	// }

	// .line1{
	// 	width: $lineWidth;
	// 	max-width: 100%;
	// 	height: $lineHeight;
	// 	background-color: $middleLineColour;
	// 	position: absolute;
	// 	top: (($outerHeight / 2) - ($lineHeight / 2));
	// 	left: 50%;
	// 	border-radius: $radius;
	// 	transform: translateX(-50%);
	// 	background-position: center center;

	// 	@media (max-width: $md){
	// 		width: $lineWidthM;
	// 		height: $lineHeightM;
	// 		top: (($outerHeightM / 2) - ($lineHeightM / 2));
	// 		border-radius: $radiusM;
	// 	}

	// 	&:before,
	// 	&:after{
	// 		width: $lineWidth;
	// 		max-width: $lineWidth;
	// 		height: $lineHeight;
	// 		position: absolute;
	// 		content: '';
	// 		left: 0px;
	// 		border-radius: $radius;
	// 		transform-origin: center center;
	// 		background-position: center center;
	// 		transition: $time;

	// 		@media (max-width: $md){
	// 			width: $lineWidthM;
	// 			height: $lineHeightM;
	// 			border-radius: $radiusM;
	// 		}
	// 	}

	// 	&:before{
	// 		transform: translateY(-#{$gap + $lineHeight});
	// 		background-color: $topLineColour;

	// 		@media (max-width: $md){
	// 			transform: translateY(-#{$gapM + $lineHeightM});
	// 		}
	// 	}

	// 	&:after{
	// 		transform: translateY(#{$gap + $lineHeight});
	// 		background-color: $bottomLineColour;

	// 		@media (max-width: $md){
	// 			transform: translateY(#{$gapM + $lineHeightM});
	// 		}
	// 	}
	// }

	// &.close{

	// 	.line1{
	// 		background-color: $middleLineColour;
	// 		transition: visibility .1s .2s, background-color .1s .2s, background-size .1s .2s;
	// 		background-size: 100% 100%;

	// 		&:before{
	// 			transform: translateY(-#{$gap + $lineHeight});
	// 			background-color: $topLineColour;
	// 			visibility: visible;
	// 			animation: before .5s linear;
	// 			transition: all $time 0s, visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;

	// 			@media (max-width: $md){
	// 				transform: translateY(-#{$gapM + $lineHeightM});
	// 				animation: beforeM .5s linear;
	// 			}
	// 		}

	// 		&:after{
	// 			transform: translateY(#{$gap + $lineHeight});
	// 			background-color: $bottomLineColour;
	// 			visibility: visible;
	// 			animation: after .5s linear;
	// 			transition: all $time 0s, visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;

	// 			@media (max-width: $md){
	// 				transform: translateY(#{$gapM + $lineHeightM});
	// 				animation: afterM .5s linear;
	// 			}
	// 		}

	// 		@keyframes before {
	// 			0% {transform: rotate(-45deg) translateY(0);}
	// 			33% {transform: rotate(0deg) translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: translateY(-#{$gap + $lineHeight});}
	// 		}

	// 		@keyframes after {
	// 			0% {transform: rotate(45deg) translateY(0);}
	// 			33% {transform: rotate(0deg) translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: translateY(#{$gap + $lineHeight});}
	// 		}

	// 		@keyframes beforeM {
	// 			0% {transform: rotate(-45deg) translateY(0);}
	// 			33% {transform: rotate(0deg) translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: translateY(-#{$gapM + $lineHeightM});}
	// 		}

	// 		@keyframes afterM {
	// 			0% {transform: rotate(45deg) translateY(0);}
	// 			33% {transform: rotate(0deg) translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: translateY(#{$gapM + $lineHeightM});}
	// 		}
	// 	}
	// }

	// &.open{
	// 	z-index: 112;

	// 	.line1{
	// 		transform-origin: 50% 50%;
	// 		transition: visibility .1s .25s, background-color .1s .25s, background-size .1s .25s;
	// 		background-color: $middleLineColourOpen;
	// 		background-size: 100% 0;

	// 		&:before{
	// 			transform: rotate(45deg) translateY(0);
	// 			background-color: $topLineColourOpen;
	// 			visibility: visible;
	// 			animation: before-open .5s linear;
	// 			transition: visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;

	// 			@media (max-width: $md){
	// 				animation: before-openM .5s linear;
	// 			}
	// 		}

	// 		&:after{
	// 			transform: rotate(-45deg) translateY(0);
	// 			background-color: $bottomLineColourOpen;
	// 			visibility: visible;
	// 			animation: after-open .5s linear;
	// 			transition: visibility .1s .25s, background-color .1s .25s, background-image .1s .25s;
				
	// 			@media (max-width: $md){
	// 				animation: after-openM .5s linear;
	// 			}
	// 		}

	// 		@keyframes before-open {
	// 			0% {transform: translateY(-#{$gap + $lineHeight});}
	// 			33% {transform: translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: rotate(45deg) translateY(0);}
	// 		}

	// 		@keyframes after-open {
	// 			0% {transform: translateY(#{$gap + $lineHeight});}
	// 			33% {transform: translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: rotate(-45deg) translateY(0);}
	// 		}

	// 		@keyframes before-openM {
	// 			0% {transform: translateY(-#{$gapM + $lineHeightM});}
	// 			33% {transform: translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: rotate(45deg) translateY(0);}
	// 		}

	// 		@keyframes after-openM {
	// 			0% {transform: translateY(#{$gapM + $lineHeightM});}
	// 			33% {transform: translateY(0);}
	// 			66% {transform: translateY(0);}
	// 			100% {transform: rotate(-45deg) translateY(0);}
	// 		}
	// 	}
	// }
}
