.pagination.pagination.pagination{
	margin-bottom: 0;
	vertical-align: middle;
	display: flex;
	justify-content: flex-end;
	align-items: center;

	.post-type-archive-campaigns &{

		@media (max-width: $md){
			margin-top: -.7rem;
		}
	}

	.pagination-mid{
		display: flex;
		justify-content: center;
		align-items: center;
	}

	div{
		text-align: center;
		width: auto;
		height: 3.2rem;
		@include font-size(20);
		line-height: 3.2rem;
		position: relative;
		z-index: 1;

		@media (max-width: $md){
			height: 2.4rem;
			line-height: 2.4rem;
		}


		&:not(.prev):not(.next){
			margin: 0 .35em;

			&.active{
				pointer-events: none;

				a{
					color: $colour;
					text-decoration: none;
				}
			}

			span,
			a{
				@include font-size(20);
				line-height: 3.2rem;
				display: block;
				color: $black;
				font-weight: 500;
				padding: 0;
				text-decoration: none;
				width: auto;
				height: auto;
				border-radius: 0;
				text-align: center;
				font-family: $main-font;
				pointer-events: none;

				@media (max-width: $md){
					@include font-size(18);
					line-height: 2.4rem;
				}

				&:hover,
				&:focus-visible{
					color: $black;
				}
			}


			&.ellipse{
				color: $grey;
			}
		}

		&.next,
		&.prev{
			margin-left: .35rem;

			span,
			a{
				@include font-size(20);
				width: 3.2rem;
				height: 3.2rem;
				display: flex;
				justify-content: center;
				align-items: center;
				text-decoration: none;

				@media (max-width: $md){
					width: 2.4rem;
					height: 2.4rem;
					line-height: 2.4rem;
				}

				svg{
					width: 1.789rem;
					aspect-ratio: 17.89/17.89;

					@media (max-width: $md){
						width: 1.341rem;
					}

					path{
						fill: $black;
					}
				}

				&:hover,
				&:focus-visible{

					svg{

						path{
							fill: darken($green, 7.5%);
						}
					}
				}
			}

			span{
				opacity: .32;
				pointer-events: none;
			}
		}
	}
}