//!!SCALE SETTINGS
$scale: true; //false, true
$vw-viewport: 1440; //If false does nothing. if true set this to the width of the design sent over

//!!GRID SETTINGS
$grid-gutter-widthWO: 16 !default; //most common gutter width

//!!PAGE WIDTH
$base-widthWO:		1280; //most common container width

//!!COLOURS
$red:				#FF4E48;

$green:				#C5FF00;

$pink:				#FF74FF;

$blue:				#70D8FF;

$orange:			#FF7F00;

$purple:			#A44CFF;

$grey-light:		#F7F6F5;
$grey:				#EFECEA;
$grey-dark:			#303030;

$white:				#FFFFFF;
$black:				#1E1E1E;

$colour: 			$black;
$main-bg-colour:	$grey-light;

//!!FONTS

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Regular.woff2') format('woff2'),
		url('../fonts/Inter-Regular.woff') format('woff'),
		url('../fonts/Inter-Regular.ttf') format('truetype');
	font-weight: 400;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-Medium.woff2') format('woff2'),
		url('../fonts/Inter-Medium.woff') format('woff'),
		url('../fonts/Inter-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Inter';
	src: url('../fonts/Inter-SemiBold.woff2') format('woff2'),
		url('../fonts/Inter-SemiBold.woff') format('woff'),
		url('../fonts/Inter-SemiBold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}





@font-face {
	font-family: 'Sharp Grotesk Bold 15';
	src: url('../fonts/SharpGroteskBold15-Regular.woff2') format('woff2'),
		url('../fonts/SharpGroteskBold15-Regular.woff') format('woff'),
		url('../fonts/SharpGroteskBold15-Regular.ttf') format('truetype');
	font-weight: 700;
	font-style: normal;
	font-display: swap;
}





@font-face {
	font-family: 'TT Norms Pro';
	src: url('../fonts/TTNormsPro-Medium.woff2') format('woff2'),
		url('../fonts/TTNormsPro-Medium.woff') format('woff'),
		url('../fonts/TTNormsPro-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
	font-display: swap;
}





$font-inter:		'Inter', sans-serif;
//regular - 400
//medium - 500
//semi - 700

$font-sharp:		'Sharp Grotesk Bold 15', serif;
//bold - 700

$font-tt:			'TT Norms Pro', serif;
//medium - 500

$main-font:			$font-inter;
$alt-font:			$font-sharp;
$alt-font2:			$font-tt;

//!!SITE TRANSITIONS
$time:				.3s;

//BREAK POINTS
$xl:				$base-widthWO - 1px ; //$base-width + - 1px
$lg:				992px; //992px - 1px
$md:				769px - 1px; //769px - 1px
$sm:				576px - 1px; //576px - 1px

//xl: $base-width
//lg: 992px
//md: 768px
//sm: 576px