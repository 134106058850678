//!!MENU
header[role="banner"]{
	z-index: 50;
	width: 100%;
	transform: translateZ(0);
	perspective: 999999px;
	position: absolute;
	top: 0;
	left: 0;
	transition: $time;
	
	// .scroll-down &,
	// .scroll-up &{

	// }

	@media (max-width: $md){
		
		.pop-open &{
			z-index: 0;
			transition: 0s;
		}
	}
}

html:has(.main-wrapper > section:first-of-type:not(.dark):not(.medium-dark):not(.light-menu)){

	#site-logo{

		svg{

			path{
				fill: $black;
			}
		}
	}

	.search-link{

		svg{
			
			path{
				fill: $black;
			}
		}

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: darken($green, 7.5%);
				}
			}
		}
	}

	.menu-top-outer .wpml-ls-legacy-dropdown li.wpml-ls-current-language > a{
		background-image: url(../images/select-icon.svg);
	}

	.menu-top-outer .wpml-ls-legacy-dropdown li.wpml-ls-current-language > a,
	.menu-top-outer .wpml-ls-legacy-dropdown li .wpml-ls-item > a{
		color: $black;

		&:hover,
		&:focus-visible{

			span{
				color: darken($green, 7.5%);
			}
		}
	}
}

#site-logo{
	display: block;
	height: auto;
	margin: 0;
	z-index: 2;
	transition: 0s;
	display: flex;
	width: 8.8rem;

	img,
	svg{
		width: 100%;
	}
}

.menu-top-outer{
	transition: $time;
	display: flex;
	justify-content: flex-end;
	flex-wrap: wrap;
	
	.col-12{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 2.4rem;
		}

		.left,
		.right{
			display: flex;
			align-items: center;
			min-width: 10rem;
		}

		.left{
			align-content: flex-start;

			@media (max-width: $md){
				min-width: 8.8rem;
			}
		}

		.right{
			justify-content: flex-end;
		}
	}

	.search-link{
		width: 2.041rem;
		display: block;

		&:hover,
		&:focus-visible{
			
			svg{

				*{
					fill: darken($green, 7.5%);
				}
			}
		}

		svg{
			width: 100%;
		}
	}

	.wpml-ls-legacy-dropdown{
		width: auto;
		background-color: transparent;
		border: none;
		margin-left: 2.4rem;
		@include font-size(16);

		@media (max-width: $md){
			margin-left: 1rem;
		}

		*{
			@include font-size(16);
		}

		li{

			&.wpml-ls-current-language{

				*{
					text-transform: uppercase;
				}

				> a{
					padding: .5rem 1.7rem .5rem 0;
					border: none;
					background-color: transparent;
					color: $white;
					background-image: url(../images/select-icon-light.svg);
					background-repeat: no-repeat;
					background-position: 100% 50%;
					background-size: 1rem auto;

					&:hover,
					&:focus-visible{

						span{
							color: darken($green, 7.5%);
						}
					}

					&:after{
						display: none;
					}
				}
			}

			.wpml-ls-sub-menu{
				border: none;
				max-width: none;
				width: auto;
			}

			.wpml-ls-item{

				> a{
					border: none;
					background-color: transparent;
					color: $white;
					padding: .5rem 0;
					white-space: nowrap;

					&:hover,
					&:focus-visible{

						span{
							color: darken($green, 7.5%);
						}
					}
				}
			}
		}
	}
}

.mob-only.mob-only{
	width: 100%;

	@media (min-width: $md + 1){
		display: none;
	}

	.social-links{
		justify-content: center;
	}
}

@import 'burger';

#mainMenu{
	display: flex;
	align-items: center;
	flex-wrap: wrap;

	@media (max-width: $md){
		margin: 0 auto;
		position: fixed;
		top: 0;
		left: 0;
		width: 100%;
		transition: $time;
		padding: 8rem 1.6rem 0;
		flex-direction: column;
		flex-wrap: nowrap;
		display: flex;
		overflow: hidden;
		z-index: 1;
		height: 100vh;
		max-height: 100vh;
		overflow: auto;
		opacity: 0;
		visibility: hidden;
		pointer-events: none;

		&:has(li.open){
			overflow: hidden;
		}

		.menu-open &{
			opacity: 1;
			visibility: visible;
			pointer-events: auto;
		}
	}	
}

@media (min-width: $md + 1){


	// #mainMenu .main-menu .current-menu-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-page-ancestor:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current_page_parent:not(.menu-item-object-custom),
	// #mainMenu .main-menu .current-menu-item:not(.menu-item-object-custom),
	#mainMenu .main-menu .current-menu-ancestor,
	#mainMenu .main-menu .current-page-ancestor,
	#mainMenu .main-menu .current-menu-parent,
	#mainMenu .main-menu .current_page_parent,
	#mainMenu .main-menu .current-menu-item{

		> a{
			color: darken($green, 7.5%);
		}
	}
}

ul.main-menu.main-menu{
	margin: 0 0;
	padding: 0;
	@include font-size(0);
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	background-color: $grey;
	border: clamp(1px, .1rem, .1rem) solid $black;
	padding: 0 .5rem;
	border-radius: 2.9155rem;

	@media (max-width: $md){
		top: 0;
		left: 0;
		width: 100%;
		display: flex;
		flex-direction: column;
		text-align: left;
		align-items: stretch;
		flex-wrap: nowrap;
		padding: 1.1rem 1.9rem;
		border-radius: 2.166rem;
		overflow: auto;
		max-height: calc(100dvh - 8rem - 1rem);
	}
	
	li{
		@include font-size(0);
		line-height: 1;
		position: static;
		display: flex;
		text-align: left;
		
		@media (min-width: $md + 1){
			margin: 0 1.74rem;

			&:hover,
			&:focus-visible{
				
				> a{
					color: darken($green, 7.5%);
				}

				&.menu-item-has-children{

					> a{
	
						&:after{
							transform: translateY(-50%) rotateX(180deg);
						}
					}
				}
			}

			img{
				margin: 0 auto 1rem;
			}

			&.mob-back{
				display: none;
			}
		}

		@media (max-width: $md){
			
			&.open{

				.sub-menu.sub-menu{
					opacity: 1;
					visibility: visible;
					pointer-events: auto;
					max-height: 9999px;
					margin-bottom: 2rem;
				}
			}
		}

		@media (max-width: $md){
			align-items: flex-start;
			padding-bottom: 0rem;
			margin-bottom: 0rem;

			img{
				display: none;
			}
		}
		
		// &:last-of-type{
		// 	margin-right: 0;
		// }

		&.no-link{

			> a{
				pointer-events: none;
			}
		}

		&.menu-item-has-children{
			flex-wrap: wrap;
			position: relative;
			
			@media (min-width: $md + 1){

				> a{
					padding-right: 1.8rem;
					position: relative;

					&:after{
						content: '';
						position: absolute;
						width: .84141rem;
						height: .4707rem;
						position: absolute;
						right: 0;
						top: 50%;
						transform: translateY(-50%) rotateX(0deg);
						background-image: url(../images/menu-arrow.svg);
						background-repeat: no-repeat;
						background-size: 100% auto;
						transition: $time;
						backface-visibility: visible!important;
					}
				}
			}

			@media (max-width: $md){
				padding-right: 4.3rem;
				position: relative;

				&:after{
					content: '';
					position: absolute;
					width: 1rem;
					aspect-ratio: 16 / 10;
					right: 0;
					top: 1.6rem;
					transform: rotateX(0deg);
					background-image: url(../images/menu-arrow.svg);
					background-repeat: no-repeat;
					background-size: 100% auto;
					transition: $time;
					backface-visibility: visible!important;
					display: block!important;
				}

				&.open{

					&:after{
						transform: rotateX(180deg);
					}
				}
			}

			.sub-menu{
				position: absolute;
				top: 100%;
				left: 50%;
				transform: translateX(-50%);
				width: 23.3rem;
				max-width: 23.3rem;
				pointer-events: none;
				opacity: 0;
				visibility: hidden;
				border-radius: 1.2rem;
			
				@media (min-width: $md + 1){
					padding: 2.6rem 3.2rem 1.6rem;

					&:after{
						content: '';
						position: absolute;
						bottom: 0;
						left: 0;
						width: 100%;
						height: calc(100% - 1rem);
						z-index: 1;
						background-color: $grey;
						border-radius: 1.2rem;
						box-shadow: 0 .3rem .8rem rgba($black, .25);
					}
				}

				@media (max-width: $md){
					position: static;
					transform: none;
					max-height: 0;
					overflow: hidden;
					width: 100%;
					max-width: 100%;
				}

				li{
					width: 100%;
					text-align: left;
					margin: 0;

					@media (max-width: $md){
						width: 100%;
						text-align: left;
					}
				}

				a{
					padding: .45rem 0;
					width: 100%;
					margin: 0 auto;

					&:after{
						display: none;
					}
				}
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					
					.sub-menu{
						pointer-events: auto;
						opacity: 1;
						visibility: visible;
						transition: $time;
					}
				}
			}
		}

		[href="#"]{
			pointer-events: none;
		}

		> a{
			@include font-size(16);
			line-height: 1.625;
			width: auto;
			color: $colour;
			text-decoration: none!important;
			font-weight: 500;
			position: relative;
			font-family: $main-font;
			padding: 1.55rem 0rem;
			z-index: 2;

			@media (max-width: $md){
				padding: .45rem 0;
			}

			@media (min-width: $md + 1){
				
				&:hover,
				&:focus-visible{
					color: darken($green, 7.5%);
				}
			}
		}
	}
}
