//---------------------------------------------------------//
@import 'normalize'; /* site wide variables (colours, font sizes) */
@import 'variables'; /* site wide variables (colours, fonts) */
@import 'toolkit'; /* mixin (@include font-size(??); , @include svg_img("../images/??");) and common classes (.clearfix, .img-replacment) */
@import 'general-tags-and-classes';
@import 'grid'; /* adapted boostap 4 grid*/
@import 'header';
@import 'footer';
@import 'pagination';
//@import 'breadcrumb';
@import 'social';
@import 'buttons';
@import 'forms';
@import 'swiper';
@import 'fancybox';
//@import 'tabs';

.waypoint{
	transition: .2s;
	opacity: 0;
	visibility: hidden;
	transform: translateY(5.0rem);
	will-change: transform;

	.inner,
	.text{

		.icon-con,
		h1,
		h2,
		h3,
		h4,
		h5,
		h6,
		p,
		li,
		blockquote,
		.btn-con{
			opacity: 0;
			visibility: hidden;
			transform: translateY(5rem);
			transition: .2s;
			will-change: transform;
		}
	}


	&.animate{
		opacity: 1;
		visibility: visible;
		transform: translateY(0);
		transition: .5s .1s;

		.inner,
		.text{

			.icon-con,
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			p,
			li,
			blockquote,
			.btn-con{
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: .5s .3s;
			}

			p,
			li,
			blockquote,
			.btn-con{
				transition: .5s .4s;
			}
		}
	}
}

.link{
	font-weight: 600;
}

//
//!!BLOCKS START
//

section{
	background-color: $main-bg-colour;

	+ section{
		position: relative;
		z-index: 1;
	}
}

.hero-section{
	position: fixed;
	top: 0;
	width: 100%;

	+ section{
		margin-top: 85.9rem;

		@media (min-width: $md + 1){
			
			@media (min-aspect-ratio: 1.68/1){
				margin-top: 100vh;
			}
		}

		@media (max-width: $md){
			margin-top: 68.8rem;
		}
	}

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(to bottom, rgba(0,0,0,1) 0%, rgba(0,0,0,0.61) 68%, rgba(0,0,0,0) 100%);
		opacity: .6;

		@media (max-width: $md){
			background: linear-gradient(-45deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0.61) 68%, rgba(0,0,0,0) 100%);
		}
	}

	.container{
		position: relative;
		z-index: 1;
		max-width: 109.6rem;
	}

	.row{
		height: 85.9rem;
		padding-top: 19rem;
		padding-bottom: .5rem;
		align-items: center;

		@media (min-width: $md + 1){
			
			@media (min-aspect-ratio: 1.68/1){
				height: 100vh;
				padding-top: 9.5rem;
			}
		}

		@media (max-width: $md){
			height: 68.8rem;
			padding-top: 14rem;
			padding-bottom: 4rem;
		}
	}

	*:not(em){
		color: $white;
	}

	.top{
		text-align: center;
	}

	.right{
		width: 39.1rem;
		margin-left: auto;

		@media (max-width: $md){
			text-align: center;
			width: 100%;
			padding-left: 0rem;
			padding-right: 0rem;
		}
	}

	h1{
		margin-bottom: .751em;

		@media (max-width: $md){
			margin-bottom: 0.5em;
		}
	}
}

.text-side-hero-section{
	position: fixed;
	top: 0;
	width: 100%;

	+ section{
		margin-top: 85.9rem;

		@media (min-width: $md + 1){
			
			@media (min-aspect-ratio: 1.68/1){
				margin-top: 100vh;
			}
		}

		@media (max-width: $md){
			margin-top: 68.8rem;
		}
	}

	&.pink .text{
		background-color: $pink;
	}

	&.blue .text{
		background-color: $blue;
	}

	&.orange .text{
		background-color: $orange;
	}

	&.green .text{
		background-color: $green;
	}

	&.purple .text{
		background-color: $purple;
	}

	&.dark .text{
		background-color: $black;
	}

	&.medium-dark .text{
		background-color: $grey-dark;
	}

	&.medium-light .text{
		background-color: $grey;
	}

	&.light .text{
		background-color: $grey-light;
	}

	&::before{
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: linear-gradient(135deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
		opacity: .4;
	}

	.row{
		height: 85.9rem;
		padding-top: 18.5rem;
		padding-bottom: 9.4rem;

		@media (min-width: $md + 1){
			
			@media (min-aspect-ratio: 1.68/1){
				height: 100vh;
				padding-top: 10.5rem;
				padding-bottom: 1.4rem;
				align-items: center;

				.text{
					padding-top: 5rem;
					padding-bottom: 5rem;
				}

				h1{
					@include font-size(78);
					margin-bottom: .24em;
				}
			}
		}

		@media (max-width: $md){
			height: 68.8rem;
			padding-top: 14rem;
			padding-bottom: 2.4rem;
			align-items: flex-end;
			text-align: center;
		}
	}

	.col-md-6{
		flex: 0 0 45.7%;
		max-width: 45.7%;
		display: flex;

		@media (max-width: $md){
			flex: 0 0 100%;
			max-width: 100%;
		}
	}

	.text{
		border-radius: .8rem;
		padding: 1rem 6.5rem;
		display: flex;
		align-items: center;

		@media (max-width: $md){
			padding: 5.8rem 1rem;

			h1{
				margin-bottom: .64em;

				br{
					display: none;
				}
			}
		}
	}

	.btn-con{
		margin-top: -.3rem;

		@media (max-width: $md){
			margin-top: -0rem;
			justify-content: center;
		}
	}
}

section:not(.hero-section){

	&.orange,
	&.purple,
	&.dark,
	&.medium-dark{
		
		*:not(em){
			color: $white;
		}

		a{

			&:hover,
			&:focus-visible{
				color: darken($green, 7.5%);
			}
		}

		[type="submit"],
		.btn{

			&:hover,
			&:focus-visible{
				background-color: $white;
				border-color: $white;
				color: $black!important;
			}
		}
	}

	&.orange,
	&.green,
	&.pink,
	&.blue,
	&.purple{

		[type="submit"],
		.btn{
			background-color: $black;
			border-color: $black;
			color: $white!important;

			&:hover,
			&:focus-visible{
				background-color: $white;
				border-color: $white;
				color: $black!important;
			}
		}
	}

	&.pink{
		background-color: $pink;
	}

	&.blue{
		background-color: $blue;
	}

	&.orange{
		background-color: $orange;
	}

	&.green{
		background-color: $green;
	}

	&.purple{
		background-color: $purple;
	}

	&.dark{
		background-color: $black;
	}

	&.medium-dark{
		background-color: $grey-dark;
	}

	&.medium-light{
		background-color: $grey;
	}

	&.light{
		background-color: $grey-light;
	}

	.row{

		*:last-child{
			margin-bottom: 0;
		}
	}
}

.center-text-hero-section{
	position: relative;
	overflow: visible!important;
	z-index: 3;

	.post-type-archive-campaigns &{

		.row{
			padding-bottom: 10.7rem;

			@media (max-width: $md){
				padding-bottom: 6.4rem;
			}
		}

		.col-12{
			padding-left: 1rem;
			padding-right: 1rem
		}

		h1{
			@include font-size(64);
			@include line-height(64,70);

			@media (max-width: $md){
				@include font-size(40);
				@include line-height(40,44);
			}
		}
	}

	&.light{

		.pattern{
			display: none;
		}
	}

	.pattern{
		position: absolute;
		left: 0;
		top: 12.2rem;
		aspect-ratio: 374.9775/698.418;
		width: 37.49775rem;
		pointer-events: none;

		@media (max-width: $md){
			display: none;
		}
	}

	.container{
		max-width: 61.6rem;
	}

	.row{
		padding-top: 19.5rem;
		padding-bottom: 13rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 14.5rem;
			padding-bottom: 5.6rem;

			h1{
				margin-bottom: .65em;
			}
		}
	}

	.col-12{

		@media (max-width: $md){
			padding-left: 1.6rem;
			padding-right: 1.6rem;
		}
	}

	&.bubble-right,
	&.bubble-left{

		.pattern{
			display: none;
		}

		&:after{
			content: '';
			width: 49.664rem;
			aspect-ratio: 1/1;
			position: absolute;
			bottom: 9.6rem;
			right: 6.7rem;
			transform: translate(50%, 50%);
			animation: bubble-big 18s 0s infinite linear;
			background-image: url(../images/bubble.png);
			background-repeat: no-repeat;
			background-size: 100% auto;

			@media (max-width: $md){
				width: 22.638rem;
				bottom: -2.2rem;
				right: -2.9rem;
			}
		}

		+ .text-with-image-section{
			
			.row{
				padding-top: 10.6rem;
				padding-bottom: 13.7rem;

				@media (max-width: $md){
					padding-top: 5.6rem;
					padding-bottom: 5.8rem;
				}
			}
		}
	}

	&.bubble-left{
		
		&:after{
			width: 54.9rem;
			right: auto;
			left: -57.6rem;
			bottom: -2.8rem;

			@media (max-width: $md){
				display: none;
			}
		}

		.row{
			padding-bottom: 5rem;
		}
	}
}

.text-hero-section{

	.post-type-archive-any &,
	.post-type-archive-regulatory-news &,
	.post-type-archive-reports &{

		.row{
			padding-top: 19.6rem;

			@media (max-width: $md){
				padding-top: 14.3rem;
			}
		}

		h1{
			width: 35rem;

			@media (max-width: $md){
				width: 22rem;
			}
		}
	}

	&.slim{

		.container{
			max-width: 88rem;
		}

		.row{
			padding-top: 19.7rem;

			@media (max-width: $md){
				padding-top: 14.2rem;
				padding-bottom: .2rem;
			}
		}

		hr{
			display: none;
		}
	}

	.btn-con{
		margin-bottom: 3.1rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}
	}

	.post-type-archive-directors &{

		.row{
			padding-top: 19.6rem;

			@media (max-width: $md){
				padding-top: 14.2rem;
			}

			h1{
				width: 30rem;
				margin-bottom: .47em;

				@media (max-width: $md){
					width: 22rem;
					margin-bottom: .67em;
				}
			}
		}
	}

	.row{
		padding-top: 15.6rem;

		@media (max-width: $md){
			padding-top: 14.2rem;
		}
	}

	h1{
		@include font-size(64);
		@include line-height(64,70);
		margin-bottom: .58em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,44);
			margin-bottom: .65em;
		}
	}
}

.center-text-section{

	.row{
		padding-top: 11rem;
		padding-bottom: 6.4rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 6.3rem;
			padding-bottom: 6.7rem;

			.col-12{
				padding-left: 1.6rem;
				padding-right: 1.6rem;
			}

			h2{
				margin-bottom: 1.2em;
			}
		}
	}
}

.vertical-scroll-slide-section{
	padding-bottom: 12rem;

	@media (max-width: $md){
		padding-bottom: 5.8rem;
	}

	.container{
		position: relative;
		z-index: 1;
		max-width: 109.6rem;
		background-color: $green;
		padding: 4rem 4.8rem;
		border-radius: .8rem;
		background-image: url(../images/green-back.jpg);
		background-repeat: no-repeat;
		background-size: cover;
		background-position: 50% 50%;

		@media (max-width: $md){
			max-width: calc(100dvw - 1.6rem - 1.6rem);
			padding: 4rem 1.2rem 1.4rem 3.2rem;
		}
	}

	.inner.inner{

		*:not(em){
			color: $colour;
		}
	}

	.row{
		align-items: center;
	}

	.vertical-scroll-slide-con{

		@media (min-width: $md + 1){
			flex: 0 0 50.3%;
			max-width: 50.3%;
		}
	}

	.vertical-scroll-image-slide-con{

		@media (min-width: $md + 1){
			flex: 0 0 49.7%;
			max-width: 49.7%;
		}
	}

	.swiper.swiper{

		.swiper-wrapper-con{
			width: 100%;
		}

		.swiper-slide{
			padding: 0;
		}
	}

	.vertical-scroll-slide{
		display: flex;
		//height: 50rem;

		@media (max-width: $md){
			pointer-events: none;

			h3,
			.btn{
				pointer-events: auto;
			}

			h3{
				display: inline-block;
			}
		}

		.swiper-wrapper{
			flex-direction: column;
			display: block;
		}

		.fake-pagination{
			position: absolute;
			top: 0;
			left: -.8rem;
			width: .4rem;
			border-radius: .2rem;
			background-color: rgba($black, .32);
			min-height: 100%;
			display: flex;
			flex-direction: column;

			div{
				min-height: 12rem;
				width: .4rem;
				border-radius: .2rem;
				flex-grow: 0;

				&.active{
					flex-grow: 1;
					background-color: $black;
				}
			}
		}

		.swiper-slide{

			&.swiper-slide-thumb-active{
				height: auto!important;
			}

			&:not(.swiper-slide-thumb-active){
				height: auto!important;

				.text{
					max-height: 0rem!important;
				}
			}

			.inner{
				padding: 2rem 5.2rem;

				@media (max-width: $md){
					padding: 0 1.2rem;
				}
			}

			.text{
				max-height: 0;
				overflow: hidden;
				transition: $time;
			}

			.text-inner{
				padding-bottom: .9rem;
			}

			h3{
				cursor: pointer;

				@media (max-width: $md){
					margin-bottom: 1.1em;
				}
			}

			p{

				&:last-of-type{
					margin-bottom: 0;
				}
				
				@media (min-width: $md + 1){
					letter-spacing: -.05em;
					@include line-height(20,32);
				}
			}

			img{
				border-radius: .6rem;
				width: 100%;
				margin-top: 3.1rem;
				margin-bottom: 2.2rem;
		
				@media (min-width: $md + 1){
					display: none;
				}
			}

			.btn-con{
				margin-top: 3.2rem;
			}
		}

		.swiper-scrollbar{
			position: absolute;
			left: 0;
			top: 0;
			height: 100%;
		}
	}

	.vertical-scroll-image-slide{

		@media (max-width: $md){
			overflow: hidden!important;
			max-height: 0!important;
		}

		.swiper-wrapper{
			flex-direction: column;
		}

		.swiper-wrapper-con,
		.swiper-slide{
			aspect-ratio: 756/954;
			height: auto!important;

			img{
				border-radius: .6rem;
				width: 100%;
			}
		}
	}
}

.block-slide-section + .logo-slide-section{

	.row{
		padding-top: 3.7rem;

		@media (max-width: $md){
			padding-top: 5.6rem;
		}
	}
}

.text-with-image-section + .logo-slide-section{

	.row{
		padding-bottom: 3.1rem;

		@media (max-width: $md){
			padding-bottom: 0rem;
		}
	}
}

.logo-slide-section{

	.row{
		padding-top: 5.4rem;
		padding-bottom: 7.2rem;
		text-align: center;

		@media (max-width: $md){
			padding-bottom: 3.8rem;
		}
	}

	.text-top{
		margin-bottom: 4.9rem;

		@media (max-width: $md){
			margin-bottom: 4.7rem;
		}
	}

	.logo-slide-con{
		width: calc(100% + 3.6rem + 3.6rem);
		max-width: calc(100% + 3.6rem + 3.6rem);
		margin-left: -3.6rem;

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-wrapper{
			transition-timing-function: linear!important;
		}

		.swiper-slide{
			padding: 0 3.6rem;
			width: 3.6rem + 3.6rem + 16.9rem;
		}
	}
}

.line-section{

	&.medium-light{

		hr{
			background-color: white;
		}
	}

	.row{
		padding-top: 6.4rem;
		padding-bottom: 6.4rem;

		@media (max-width: $md){
			padding-top: 5.6rem;
			padding-bottom: 5.6rem;
		}
	}

	hr{
		margin: 0;
		background-color: $green;
		opacity: .32;
	}
}

.featured-article-section,
.news-insights-two-section,
.news-insights-section{

	.container{
		max-width: 130.4rem;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 1.2rem;
			padding-left: 1.2rem;
		}

		.row {
			margin-right: -1.2rem;
			margin-left: -1.2rem;
		}
	}

	.row{
		padding-top: 6.2rem;
		padding-bottom: 6.2rem;

		@media (max-width: $md){
			padding-top: .7rem;
			padding-bottom: 0rem;
		}
	}

	.text-top{
		margin-bottom: 6.5rem;
		text-align: center;

		@media (max-width: $md){
			margin-bottom: 4.9rem;
		}
	}

	.link-con{
		margin-top: 1.1rem;
		text-align: center;

		@media (max-width: $md){
			margin-top: 1.4rem;
		}

		.link{

			@media (max-width: $md){
				@include font-size(14);
			}
		}
	}
}

.text-with-image-section + .news-insights-two-section{

	.row{
		padding-top: 12.8rem;

		@media (max-width: $md){
			padding-top: 6.5rem;
		}
	}
}

.logo-slide-section + .news-insights-two-section{

	.row{
		padding-top: 8.7rem;

		@media (max-width: $md){
			padding-top: 5.7rem;
		}
	}
}

.news-insights-two-section{

	.row{
		padding-top: 2rem;
	}

	.text-top{
		margin-bottom: 12.7rem;
		text-align: left;

		@media (max-width: $md){
			margin-bottom: 4.7rem;
			text-align: center;

			h2{
				padding-left: 5rem;
				padding-right: 5rem;
				margin-bottom: 1.15em;
			}
			
		}
	}
}

.hn-item{
	margin-bottom: 4.8rem;

	&.waypoint{
		
		.text{
			opacity: 0;
			visibility: hidden;
			transform: translateY(5rem);
			transition: .2s;
			will-change: transform;
		}

		&.animate{

			.text{
				opacity: 1;
				visibility: visible;
				transform: translateY(0);
				transition: .5s .3s;
			}
		}
	}

	@media (max-width: $md){
		margin-bottom: 2.4rem;
	}

	@media (min-width: $md + 1){
		
		&:not(.col-md-6){

			.inner{
				height: 65.2rem;
				align-items: stretch;
			}

			.text.text{
				width: 41.7rem;
				display: flex;
				flex-direction: column;
				justify-content: space-between;
			}
		}
	}
	
	.inner{
		height: 72.4rem;
		border-radius: .8rem;
		padding: 1rem 1rem;
		display: flex;
		align-items: flex-end;
		padding: 4rem 2.4rem;
		background-color: rgba($white, .82);

		@media (max-width: $md){
			height: 54rem;
			padding: 1.6rem 1.6rem;
		}
	}

	.text.text{
		border-radius: .8rem;
		padding: 3.4rem 3.2rem;
		width: 100%;
		background-color: $pink;

		@media (max-width: $md){
			padding: 3.2rem 3.2rem;

			.btn-con{
				margin-top: 3.4rem;
			}
		}

		*:not(em){
			color: $colour;
		}

		h4{
			margin-bottom: .52em;

			@media (max-width: $md){
				margin-bottom: .7em;
			}
		}

		p{

			@media (max-width: $md){
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				overflow: hidden;
				margin-bottom: 1em;
			}
		}
	}
}

.locations-section + .marquee-section{
	padding-bottom: 25.3rem;

	@media (max-width: $md){
		padding-bottom: 8.6rem;
	}
}

.news-insights-section.dark + .marquee-section.dark{

	@media (max-width: $md){
		padding-top: 7.6rem;
	}
}

.marquee-section{
	padding-top: 1.1rem;
	padding-bottom: 1.1rem;

	.marquee-slide{
		pointer-events: none;
		width: auto;
		max-width: none;

		*{
			width: auto;
			max-width: none;
		}

		.swiper-wrapper-con{
			overflow: visible!important;
		}

		.swiper-wrapper{
			//transition-duration: 8000ms!important;
			transition-timing-function: linear!important;
		}

		.swiper-slide{
			width: auto;
			max-width: none;
		}

		.inner{
			@include font-size(112);
			line-height: 1.2;
			letter-spacing: -.017em;
			white-space: nowrap;
			padding: 0 .1em;
			font-weight: 500;

			@media (max-width: $md){
				@include font-size(56);
			}
		}
	}
}

body:not(:has(.center-text-hero-section.bubble-right, .center-text-hero-section.bubble-left)) .center-text-hero-section ~ .cta-section{
	
	&:after{
		content: '';
		position: absolute;
		top: 17.4rem;
		right: 0;
		aspect-ratio: 861.7251/866.6705;
		width: 86.17251rem;
		background-image: url(../images/pattern2.svg);
		background-repeat: no-repeat;
		background-size: 100% auto;
		pointer-events: none;

		@media (max-width: $md){
			top: 24.1rem;
			right: -8rem;
			width: 36.07251rem;
		}
	}
	
	.bubble-big,
	.bubble-small{
		display: none;
	}

	.row{

		@media (min-width: $md + 1){
			padding-bottom: 12.7rem;
		}

		@media (max-width: $md){
			padding-top: 13.7rem;
		}
	}
}

.news-insights-two-section + .cta-section.light{

	.row{
		@media (max-width: $md){
			padding-top: 12.9rem;
			padding-bottom: 12.9rem;
		}
	}
}

.cta-section{
	position: relative;

	&.light{

		.row{
			padding-top: 12.8rem;
			padding-bottom: 19.2rem;

			@media (max-width: $md){
				padding-top: 7.2rem;
				padding-bottom: 7.2rem;
			}
		}
	}

	body:has(.center-text-hero-section.bubble-right) &,
	body:has(.center-text-hero-section.bubble-left) &{

		.bubble-big{
			bottom: -25.3rem;
			right: 39.9rem;

			@media (max-width: $md){
				width: 23.485rem;
				display: block;
				bottom: -.1rem;
				right: 0rem;
				z-index: 2;
			}
		}

		.bubble-small{
			bottom: -4.3rem;

			@media (max-width: $md){
				width: 12.038rem;
				display: block;
				bottom: 7.9rem;
				right: -.2rem;
			}
		}
	}

	.bubble-big,
	.bubble-small{
		width: 54.9rem;
		position: absolute;
		bottom: -2.4rem;
		right: 0;
		transform: translate(50%, 50%);
	}

	.bubble-big{
		animation: bubble-big 18s 0s infinite linear;

		@media (max-width: $md){
			display: none;
		}

		img{
			animation: bubble-big-img 8s 0s infinite linear;
		}
	}

	.bubble-small{
		width: 28.998rem;
		bottom: -1.5rem;
		right: -3.7rem;
		animation: bubble-small 20s 0s infinite linear;

		@media (max-width: $md){
			width: 12.038rem;
			bottom: 8rem;
			right: 6.3rem;
		}

		img{
			animation: bubble-small-img 9s 0s infinite linear;
		}
	}

	@keyframes bubble-big{
		0%,
		100%{
			transform: translate(50%, 50%);
		}

		50%{
			transform: translate(60%, 50%);
		}
	}

	@keyframes bubble-big-img{
		0%,
		100%{
			transform: translate(0%, 0%);
		}

		50%{
			transform: translate(0%, 4%);
		}
	}

	@keyframes bubble-small{
		0%,
		100%{
			transform: translate(50%, 50%);
		}

		50%{
			transform: translate(40%, 50%);
		}
	}

	@keyframes bubble-small-img{
		0%,
		100%{
			transform: translate(0%, 0%);
		}

		33.3%{
			transform: translate(0%, -5%);
		}

		66.6%{
			transform: translate(0%, 4%);
		}
	}

	.row{
		padding-top: 19.4rem;
		padding-bottom: 14.3rem;
		text-align: center;

		@media (max-width: $md){
			padding-bottom: 12.8rem;

			h2{
				margin-bottom: 1.45em;
			}
		}
	}

	.btn-con{
		margin-top: -.85rem;
	}
}

.simple-hero-section{

	.container{
		max-width: 123.2rem;
	}

	.row{
		padding-top: 20.2rem;
		padding-bottom: 11rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 14.8rem;
			padding-bottom: 5.5rem;
			text-align: center;

			h1{
				margin-bottom: .68em;
			}
		}
	}

	.text-side{
		padding-bottom: .6rem;

		@media (min-width: $md + 1){
			flex: 0 0 51.9%;
			max-width: 51.9%;
		}

		@media (max-width: $md){
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			margin-bottom: 4.2rem;
		}

		.text{
			width: 53rem;
		}
	}

	.img-side{

		@media (min-width: $md + 1){
			flex: 0 0 48.1%;
			max-width: 48.1%;
		}

		img{
			border-radius: .8rem;

			@media (max-width: $md){
				aspect-ratio: 343/311;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}
	}

	.btn-con{
		margin-top: 3.1rem;

		@media (max-width: $md){
			justify-content: center;
		}
	}
}

.text-with-image-section{

	&:has(+ .line-section){

		.row{
			padding-bottom: 0rem;

			@media (min-width: $md + 1){
				padding-top: 8.9rem;
			}
		}

		+ .line-section{

			.container{
				max-width: 116.8rem;
			}

			.row{
				padding-top: 6.9rem;
				padding-bottom: 5.7rem;

				@media (max-width: $md){
					padding-top: 5.6rem;
					padding-bottom: 5.6rem;
				}
			}
		}
	}

	&.right{

		.text-side{
			
			@media (min-width: $md + 1){
				padding-left: 11.6rem;
			}
		}
	}

	&.left{

		@media (min-width: $md + 1){
			
			.img-side{
				order: 2;
			}
		}

		.text-side{

			.text{
				width: 48rem;
			}
		}
	}
	
	.container{
		max-width: 116.8rem;
	}

	.row{
		padding-top: 13rem;
		padding-bottom: 12.6rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 5.6rem;
			padding-bottom: 5.6rem;
		}
	}

	.img-side{
		position: relative;

		@media (min-width: $md + 1){
			flex: 0 0 45.4%;
			max-width: 45.4%;
		}

		@media (max-width: $md){
			margin-bottom: 4.7rem;
		}

		img{
			border-radius: .8rem;

			@media (max-width: $md){
				aspect-ratio: 343/311;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.img-1{
			width: 78%;

			@media (max-width: $md){
				aspect-ratio: 265.98/337.72;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.img-2{
			width: 62.9%;
			float: right;
			margin-top: -19.7rem;
			margin-right: -.2rem;

			@media (max-width: $md){
				aspect-ratio: 214.67/209.1;
				object-fit: cover;
				object-position: 50% 50%;
				margin-top: -13.3rem;
			}
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 54.6%;
			max-width: 54.6%;
		}

		h3{

			@media (max-width: $md){
				@include font-size(28);
				@include line-height(28,32);
				margin-bottom: .9em;
			}
		}
	}
}

.line-section + .block-slide-section,
.line-section + .block-section{

	&:has(.text-con){

		.row{
			padding-top: 6.3rem;
			padding-bottom: 1rem;

			@media (max-width: $md){
				padding-top: .6rem;
			}
		}
	}

	.row{
		padding-top: 0;
		padding-bottom: 4.9rem;

		@media (max-width: $md){
			padding-bottom: 5.7rem;
		}
	}
}

.line-section + .block-slide-section{

	.row{
		padding-bottom: 8.7rem;

		@media (max-width: $md){
			padding-bottom: 6.5rem;
		}
	}
}

.block-slide-section,
.half-blocks-section,
.block-section{

	&.orange,
	&.purple,
	&.dark,
	&.medium-dark{
		
		.swiper-slide,
		.col-md-6,
		.col-md-4{

			.inner{
				background-color: rgba($white, .08);
			}
		}
	}

	&.green{

		.col-md-4{

			.inner.inner.inner{

				&:has(.block-link:hover){
					background-color: rgba($black, .08);
				}
			}
		}
	}

	.container{
		max-width: 117.6rem;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: 1.2rem;
			padding-left: 1.2rem;
		}

		.row {
			margin-right: -1.2rem;
			margin-left: -1.2rem;
		}
	}

	.row{
		padding-top: 15.7rem;
		padding-bottom: 11.2rem;

		@media (max-width: $md){
			padding-bottom: 6.4rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 6.5rem;

		@media (max-width: $md){
			margin-bottom: 4.9rem;
		}
	}

	.swiper-slide,
	.col-md-6,
	.col-md-4{
		margin-bottom: 4rem;
		display: flex;

		@media (min-width: $md + 1){
			margin-bottom: 4rem!important;
		}

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}

		.inner{
			border-radius: .8rem;
			background-color: white;
			padding: 6.4rem 3.2rem;
			position: relative;
			transition: $time;
			width: 100%;

			@media (max-width: $md){
				padding: 5rem 3.2rem 4.6rem;
			}
		}

		.icon-con{
			margin-bottom: 2.4rem;
		}
		
		h4{
			padding-right: 2rem;

			@media (max-width: $md){
				padding-right: 8.5rem;
				margin-bottom: .7em;
			}
		}
	}

	.col-md-4{

		.inner.inner.inner{

			&:has(.block-link:hover){
				background-color: $green;

				*{
					color: $black;
				}
			}

			.block-link{
				position: absolute;
				top: 0;
				left: 0;
				width: 100%;
				height: 100%;
				z-index: 2;
				border-radius: .8rem;
			}

			*{
				transition: $time 0s;
			}
		}
	}

	.block-slide.block-slide.block-slide{
		width: calc(100% + 1.2rem + 1.2rem);
		max-width: calc(100% + 1.2rem + 1.2rem);
		margin-left: -1.2rem;
		margin-bottom: 5.5rem;

		@media (max-width: $md){
			width: calc(100% + .4rem + .4rem);
			max-width: calc(100% + .4rem + .4rem);
			margin-left: -.4rem;

			.swiper-wrapper-con{
				overflow: visible!important;
			}
		}

		.swiper-slide{
			margin-bottom: 0;
			padding: 0 1.2rem;

			@media (min-width: $md + 1){
				margin-bottom: 0!important;
			}

			@media (max-width: $md){
				padding: 0 .4rem;
			}

			.inner{
				background-color: rgba($white, .24);
			}
		}
	}

	.btn-under{
		margin-top: 7.2rem;

		@media (max-width: $md){
			margin-top: 4.1rem;

			.btn{
				font-size: 1.8rem!important;
				padding: 1.712rem 3.1rem;
			}
		}
	}
}

.block-section{

	@media (max-width: $md){
		overflow: visible!important;
	}

	body:has(.center-text-hero-section.bubble-right) &,
	body:has(.center-text-hero-section.bubble-left) &{

		&:after{
			content: '';
			width: 16.617rem;
			aspect-ratio: 1/1;
			position: absolute;
			top: -6.7rem;
			left: -13.9rem;
			transform: translate(50%, 50%);
			animation: bubble-big 18s 0s infinite linear;
			background-image: url(../images/bubble.png);
			background-repeat: no-repeat;
			background-size: 100% auto;

			@media (max-width: $md){
				width: 11.191rem;
				top: -9rem;
				left: -11.2rem;
			}
		}
	}

	&:has(.text-con){

		.row{
			padding-top: 15rem;

			@media (max-width: $md){
				padding-top: 6.3rem;

				.text-con{
					padding-left: 1.6rem;
					padding-right: 1.6rem;
				}

				h2{
					margin-bottom: 1.2em;
				}
			}
		}
	}

	.col-md-4{

		@media (min-width: $md + 1){
			
			.inner{
				min-height: 28.8rem;
			}
		}
	}
}

.half-blocks-section{

	.single-campaigns &,
	.page &{

		.container{
			max-width: 108.8rem;
		}

		.row{
			padding-top: 8.4rem;
			padding-bottom: 9.6rem;

			@media (max-width: $md){
				padding-top: 9rem;
				padding-bottom: 6.6rem;
			}
		}

		.text-con{
			margin-bottom: 6.4rem;

			@media (max-width: $md){
				margin-bottom: 4.9rem;
				
				h2{
					margin-bottom: 1.15em;
				}
			}
		}
	}

	.container{
		max-width: 90.4rem;
	}

	.row{
		padding-top: 1.4rem;
		padding-bottom: 0;

		@media (max-width: $md){
			padding-bottom: 4rem;
		}
	}

	.text-con{
		text-align: left;
		margin-bottom: 3.3rem;
	}

	.col-md-6{

		h2{
			margin-bottom: .35em;

			@media (max-width: $md){
				margin-bottom: .6em;
			}
		}
	}
}

.quote-section{

	.single &{
		margin-top: -1.5rem;

		.container{
			max-width: 84.8rem;

			@media (max-width: $md){
				max-width: calc(100dvw - .8rem - .8rem);
			}
		}

		.row{
			padding-top: 0;
			padding-bottom: 0;
		}

		.col-12{
			padding: 5.5rem 5.6rem;

			@media (max-width: $md){
				padding: 4.8rem 1.6rem;
			}
		}

		blockquote{
			@include font-size(20);
			@include line-height(20,28);
			font-weight: 500;

			@media (max-width: $md){
				@include font-size(22);
				@include line-height(22,26);
				font-weight: 600;
			}

			&:has(~ .by){
				margin: 0 0 1.6em;

				@media (max-width: $md){
					margin: 0 0 1.5em;
				}
			}
		}
	}

	.container{
		max-width: 128rem;

		@media (max-width: $md){
			max-width: calc(100dvw - .8rem - .8rem);
		}
	}

	.row{
		padding-top: 11.2rem;
		padding-bottom: 11.2rem;

		@media (max-width: $md){
			padding-top: 5.5rem;
			padding-bottom: 5.5rem;
		}
	}

	.col-12{
		background-color: white;
		border-radius: .8rem;
		padding: 8.7rem 12.1rem;

		@media (max-width: $md){
			padding: 4.8rem 1.6rem;
		}
	}

	blockquote{
		@include font-size(32);
		@include line-height(32,40);

		@media (max-width: $md){
			@include font-size(22);
			@include line-height(22,26);
		}
	}
}

.events-section{

	.row{
		padding-top: 15rem;
		padding-bottom: 22.9rem;

		@media (max-width: $md){
			padding-top: 6.2rem;
			padding-bottom: 13.4rem;
		}
	}

	.text-con{
		margin-bottom: 6.2rem;

		@media (max-width: $md){
			margin-bottom: 3.2rem;
		}
	}
}

.e-item{
	margin-bottom: 3.2rem;

	@media (max-width: $md){
		margin-bottom: 2.6rem;
	}

	.inner{
		padding: 3.2rem 6.2rem 3.2rem 3.2rem;
		background-color: $white;
		border-radius: .8rem;
		position: relative;
		transition: $time;

		@media (max-width: $md){
			padding: 3rem 6.2rem 3rem 3.2rem;
		}

		&:hover,
		&:focus-visible{
			background-color: $green;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			// &:hover,
			// &:focus-visible{
				
			// 	svg{

			// 		*{
			// 			fill: darken($green, 7.5%);
			// 		}
			// 	}

			// 	~ h4{
			// 		color: darken($green, 7.5%);
			// 	}
			// }

			svg{
				position: absolute;
				top: 50%;
				right: 3.9rem;
				transform: translateY(-50%);
				width: 1.789rem;
				aspect-ratio: 17.89/17.89;

				@media (max-width: $md){
					top: 4.7rem;
				}

				*{
					transition: $time;
				}
			}
		}
	}

	h4{
		margin-bottom: .55em;
		transition: $time;

		@media (max-width: $md){
			margin-bottom: .85em;
		}
	}
}

.text-with-image-section + .consultant-stories-section{

	.row{
		padding-top: 17.5rem;

		@media (max-width: $md){
			padding-top: 6.1rem;
			padding-bottom: 12.9rem;
		}
	}
}

.consultant-stories-section{
	
	.row{
		padding-top: 12.7rem;
		padding-bottom: 20.9rem;

		@media (max-width: $md){
			padding-top: 5.6rem;
			padding-bottom: 12.9rem;
		}
	}

	.text-con{
		margin-bottom: 6.2rem;

		@media (max-width: $md){
			margin-bottom: 3.2rem;
			text-align: center;
		}
	}

	.consultant-slide{

		.inner-slide.inner-slide{
			width: calc(100% + .8rem + .8rem);
			max-width: calc(100% + .8rem + .8rem);
			margin: 0 -.8rem;
			display: flex;
			flex-wrap: wrap;
		}
	}
}

.c-item{
	width: 33.3333%;

	@media (max-width: $md){
		width: 100%;
		margin-bottom: 2.4rem;
	}

	.inner{
		padding: 0 .8rem;
	}

	.img-con{
		aspect-ratio: 416/404;
		border-radius: .8rem;

		@media (max-width: $md){
			aspect-ratio: 343/350;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.text{
		padding: 3.1rem .8rem;

		@media (max-width: $md){
			padding: 2.3rem .8rem;
		}
	}

	h4{
		margin-bottom: .25em;

		@media (max-width: $md){
			margin-bottom: .35em;
		}
	}

	p{
		margin-bottom: .9em;
	}

	a{
		margin-top: .8rem;
		font-weight: 600;
		display: inline-block;
	}
}

.share-with-text-section{

	.container{
		max-width: 110rem;
	}

	.row{
		padding-top: 10.4rem;
		padding-bottom: 12.4rem;
		align-items: center;

		@media (max-width: $md){
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}

	.share.share{
		background-color: white;
		padding: 4.7rem 3.1rem;
		border-radius: .8rem;
		width: 41.5rem;

		@media (max-width: $md){
			padding: 4.1rem 2.7rem 2.9rem;

			h4{
				@include font-size(26.38);
			}
		}

		*:not(em){
			color: $colour;
		}

		iframe{
			width: 100%;
			border: none;
		}
	}

	.share-side{

		@media (min-width: $md + 1){
			padding-left: 2.4rem;
			flex: 0 0 55%;
			max-width: 55%;
		}

		@media (max-width: $md){
			margin-bottom: 4.7rem;
		}
	}

	.text-side{

		@media (min-width: $md + 1){
			flex: 0 0 45%;
			max-width: 45%;
		}

		p + p.large{
			margin-top: 3.1em;
		}
	}
}

.line-section + .financial-highlights-section{

	.row{
		padding-top: 0rem;
	}
}

.financial-highlights-section{

	.row{
		padding-top: 4.6rem;
		padding-bottom: 4.6rem;

		@media (max-width: $md){
			padding-top: .2rem;
			padding-bottom: .2rem;
		}
	}

	.bottom,
	.top{
		width: 100%;
		margin-bottom: 3.2rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}
	}
	

	.bottom{
		max-height: 0;
		overflow: hidden;
		margin-bottom: 0;
		transition: $time;

		.bottom-inner{
			width: 100%;
		}

		@media (min-width: $md + 1){
			
			.highlights.highlights.highlights.highlights{

				span{
					display: none;
				}
			}
		}
	}

	.text-top{
		margin-bottom: 11.1rem;
		text-align: center;

		@media (max-width: $md){
			margin-bottom: 3.2rem;
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			
			h2{
				margin-bottom: 1.2em;
			}
		}
	}

	.link-con{
		margin-top: 5.7rem;
		text-align: center;

		&.open{
			display: none;
		}

		@media (max-width: $md){
			margin-top: 3.6rem;

			.link{
				@include font-size(14);
			}
		}
	}

	.highlights{
		margin-bottom: 3.2rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}

		&:not(:has(+ .highlights)){
			margin-bottom: 0;
		}

		@media (min-width: $md + 1){
			
			span{
				color: rgba($white, .4)!important;
				position: absolute;
				top: -4rem;
				left: 0;
				width: 100%;
				display: block;
				text-align: center;
			}


			+ .highlights{

				span{
					display: none;
				}
			}
		}
	
		.inner{
			border-radius: .8rem;
			background-color: white;
			display: flex;
			align-items: center;

			@media (max-width: $md){
				flex-wrap: wrap;
			}
		}

		.name,
		.date1,
		.date2{

			*{
				color: $colour;
			}
		}

		.name{
			width: 41%;
			padding: 1rem 3.2rem;

			@media (max-width: $md){
				padding: 3.1rem 3.2rem .9rem;
				width: 100%;
			}
		}

		.date1,
		.date2{
			text-align: center;
			width: 22%;
			padding: 3.9rem 3.2rem;
			position: relative;

			@media (max-width: $md){
				padding: .8rem 3.2rem;
				width: 100%;
				text-align: left;
				display: flex;

				div{
					min-width: 10.4rem;
				}

				span{
					color: rgba($black, .64);
				}
			}
		}

		.change{
			text-align: right;
			width: 15%;
			padding: 3.9rem 3.2rem;
			position: relative;

			@media (min-width: $md + 1){
				
				span{
					text-align: right;
					padding-right: 1.3rem;
				}
			}

			@media (max-width: $md){
				width: 100%;
				padding: .9rem 3.2rem 3.2rem;
				display: flex;

				> div{
					min-width: 10.4rem;
					text-align: left;
				}

				span{
					display: block;
					color: rgba($black, .64);
				}
			}

			.plus,
			.minus{
				background-color: $green;
				display: inline-block;
				@include font-size(16);
				border-radius: .8rem;
				padding: .4rem .6rem;
				font-weight: 500;
				min-width: 6rem;
				text-align: left;

				@media (max-width: $md){
					@include font-size(14);
					padding: .2rem .6rem;
					min-width: 5.4rem;
					border-radius: .4rem;
				}

				@media (min-width: $md + 1){
					margin-top: -.2rem;
					margin-bottom: -.2rem;
				}
			}

			.plus{
				color: $colour;
			}

			.minus{
				background-color: $red;
				color: white;
			}
		}
	}
}

.line-section + .financial-calendar-section{

	.row{
		padding-top: 0rem;
	}
}

.financial-calendar-section{

	.row{
		padding-top: 12.7rem;
		padding-bottom: 12.7rem;

		@media (max-width: $md){
			padding-top: 5.8rem;
			padding-bottom: 5.8rem;
		}
	}

	.text-top{
		margin-bottom: 6.2rem;
		text-align: center;

		@media (max-width: $md){
			padding-left: 1.6rem;
			padding-right: 1.6rem;
			margin-bottom: 3.2rem;
			
			h2{
				margin-bottom: 1.1em;
			}
		}
	}

	.link-con{
		margin-top: 5.7rem;
		text-align: center;

		@media (max-width: $md){
			margin-top: 3.7rem;

			.link{
				@include font-size(14);
			}
		}
	}

	.cal-item.cal-item{
		margin-bottom: 3.2rem;

		@media (max-width: $md){
			margin-bottom: 2.5rem;
		}

		&:not(:has(+ .cal-item)){
			margin-bottom: 0;
		}

		.inner{
			border-radius: .8rem;
			background-color: white;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
			padding: 3.2rem 3.2rem;
		}

		*{
			color: $colour;
		}

		h4{
			width: 100%;
			margin-bottom: .55em;

			@media (max-width: $md){
				margin-bottom: .75em;
			}
		}

		p{

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,26);
			}

			span{

				@media (max-width: $md){
					display: block;
					margin-bottom: .4em;
				}
			}
		}
	}
}

.block-section + .board-of-directors-section{

	.row{
		padding-top: 10.7rem;

		@media (max-width: $md){
			padding-top: 15rem;
			padding-bottom: 12.2rem;
		}
	}
}

.board-of-directors-section{

	.row{
		padding-top: 12.7rem;
		padding-bottom: 12.8rem;
		justify-content: center;

		@media (max-width: $md){
			padding-top: 6.4rem;
			padding-bottom: 6.6rem;
		}
	}

	hr{
		background-color: $green;
		opacity: .32;
	}

	.text-top{
		margin-bottom: 6.6rem;

		@media (max-width: $md){
			margin-bottom: 4.8rem;
			text-align: center;

			h2{
				margin-bottom: 1.14em;
			}
		}
	}

	.link-con{
		margin-top: .7rem;
		text-align: center;

		@media (max-width: $md){
			margin-top: 1.5rem;
			
			.link{
				@include font-size(14);
			}
		}
	}
}

.d-item{
	margin-bottom: 4.9rem;

	@media (max-width: $md){
		margin-bottom: 2.45rem;
	}

	.inner{
		cursor: pointer;

		&:hover,
		&:focus-visible{
			
			.text{
				
				h4{
					color: darken($green, 7.5%);
				}
			}
		}
	}

	.img-con{
		aspect-ratio: 416/404;
		border-radius: .8rem;

		@media (max-width: $md){
			aspect-ratio: 343/350;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.text{
		padding: 3.2rem .8rem 0;
		min-height: 14rem;

		@media (max-width: $md){
			padding: 2.3rem 0.8rem 0;
			min-height: 10.5rem;
		}
	}

	h4{
		margin-bottom: .25em;
		transition: $time;

		@media (max-width: $md){
			margin-bottom: .9rem;
		}
	}

	p{
		margin-bottom: .9em;
	}

	a{
		margin-top: .8rem;
		font-weight: 600;
		display: inline-block;
	}
}

.pop-up-outer{
	display: flex;
	position: fixed;
	background-color: rgba($black, .75);
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;
	z-index: 999999;
	width: 100%;
	height: 100dvh;
	opacity: 0;
	visibility: hidden;
	pointer-events: none;
	transition: $time;

	&.open{
		opacity: 1;
		visibility: visible;
		pointer-events: auto;
	}

	.pop-up.pop-up{
		width: 128rem;
		max-width: calc(100% - 3.2rem);
		background-color: white;
		border-radius: .8rem;
		display: flex;
		flex-wrap: wrap;
		position: relative;
		padding: 8.2rem 4.8rem 5rem;
		max-height: calc(100svh - 3.2rem);
		overflow: auto;

		@media (max-width: $md){
			padding: 7.4rem 1.6rem 2.5rem;
		}

		.close{
			position: absolute;
			top: 4.9rem;
			right: 4.8rem;
			text-decoration: none;

			@media (max-width: $md){
				@include font-size(18);
				top: 2.6rem;
				right: 1.5rem;
			}

			&:hover,
			&:focus-visible{
				color: darken($green, 7.5%);
			}
		}

		*:not(em){
			color: $colour;
		}

		.img-con{
			aspect-ratio: 416/404;
			border-radius: .8rem;
			width: 35.1%;
			align-self: start;

			@media (max-width: $md){
				width: 100%;
				aspect-ratio: 311/350;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.text{
			width: 64.9%;
			align-self: center;

			@media (min-width: $md + 1){
				padding-left: 5.6rem;
				padding-right: 5.6rem;
			}

			@media (max-width: $md){
				width: 100%;
				padding: 3.14rem 1.6rem 0;
			}
		}

		h4{
			margin-bottom: .25em;

			@media (max-width: $md){
				margin-bottom: .4em;
			}
		}

		p.large{
			margin-bottom: 1.25em;

			@media (max-width: $md){
				margin-bottom: 1.85em;
			}
		}
	}
}

.filter-section{

	&.more{

		.search-con{
			margin-left: 0;
			margin-right: 1.6rem;
		}

		@media (max-width: $md){
			
			.clear-btn{
				order: 1;
			}

			.radio,
			.check{
				order: 2;
			}
		}
	}

	.blog &{

		.clear-btn + .check{

			@media (min-width: $md + 1){
				max-width: calc(100% - 34.3rem - 2rem - 2.3rem);
			}
		}

		.check{

			@media (min-width: $md + 1){
				max-width: calc(100% - 34.3rem - 2rem);
			}
		}
	}

	.top{
		display: flex;
		flex-wrap: wrap;
		width: 100%;
		margin-bottom: 2.9rem;
		align-items: flex-end;

		@media (max-width: $md){
			order: 3;
			margin-bottom: -1.8rem;

			.search-con{
				margin-bottom: 1.5rem !important;
			}
		}

		.showing{
			margin-left: auto;
			font-weight: 500;
			margin-bottom: .4rem!important;

			@media (max-width: $md){
				margin-left: 0;
				margin-top: 3.6rem;
			}
		}
	}

	#filter-form{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
		padding-top: 2.3rem;
		padding-bottom: 6rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 4.5rem;
		}
	}

	.clear-btn{
		border: .1rem solid $black;
		width: 3.9rem;
		height: 3.9rem;
		border-radius: 3.9rem;
		position: relative;
		margin-right: .4rem;
		margin-bottom: .4rem!important;

		@media (max-width: $md){
			width: 3.6rem;
			height: 3.6rem;
			margin-bottom: 2.2rem!important;
		}

		&:hover,
		&:focus-visible{
			background-color: $black;
			
			&::before,
			&::after{
				background-color: $white;
			}
		}

		&::before,
		&::after{
			content: '';
			width: 1.5rem;
			height: .1rem;
			background-color: $black;
			position: absolute;
			left: 50%;
			top: 50%;
		}

		&::before{
			transform: translate(-50%, -50%) rotate(45deg);
		}

		&::after{
			transform: translate(-50%, -50%) rotate(-45deg);
		}

		@media (max-width: $md){
			
			+.radio,
			+ .check{
				width: calc(100% - 3.6rem - .4rem + 1.6rem);
				max-width: calc(100% - 3.6rem - .4rem + 1.6rem);
			}
		}
	}

	.radio,
	.check{
		display: flex;
		flex-wrap: wrap;
		align-items: center;

		@media (max-width: $md){
			overflow: auto;
			display: inline-flex;
			flex-wrap: nowrap;
			width: calc(100% + 1.6rem);
			max-width: calc(100% + 1.6rem);
			margin-right: -1.6rem;
			margin-bottom: 1.9rem!important;
		}
	}


	input[type="radio"],
	input[type="checkbox"]{
		display: none;

		& + label{
			@include font-size(12);
			line-height: 1.2;
			padding: 1.164rem 1.6rem!important;
			color: $colour;
			font-weight: 400;
			text-transform: uppercase;
			width: auto;
			border: .1rem solid $black;
			border-radius: 1.95rem;
			margin-right: .4rem;
			margin-bottom: .4rem!important;
			display: flex;
			align-items: center;

			@media (max-width: $md){
				padding: 1.014rem .9rem!important;
				white-space: nowrap;
			}

			i{
				display: block;
				width: 1.2rem;
				height: 1.2rem;
				border-radius: 1.2rem;
				margin-right: .8rem;
			}

			&:before,
			&:after{
				display: none;
			}

			&:hover,
			&:focus-visible{
				background-color: $black;
				color: $white;
			}
		}

		&:checked + label{
			background-color: $black;
			color: $white;
		}
	}

	.search-con,
	.year-con{

		@media (max-width: $md){
			width: 100%;
		}
	
		label{
			color: $colour;
		}
	}

	.year-con{
		position: relative;
		margin-bottom: .4rem!important;

		select{
			border: .1rem solid $black;
			background-color: transparent;
		}
	}

	.search-con{
		margin-left: auto;
		position: relative;
		margin-bottom: .4rem!important;

		[type="search"]{
			background-color: transparent;
			border: .1rem solid $black;
			min-width: 34.3rem;
			padding-right: 5rem;
		}

		[type="submit"]{
			position: absolute;
			bottom: 2.8rem;
			right: 1.2rem;
			transform: translateY(50%);
			background-color: transparent;
			border: none;
			padding: 0;
			width: 3.2rem;
			height: 3.2rem;

			@media (max-width: $md){
				bottom: 2.8rem;
			}

			&:hover,
			&:focus-visible{
				
				svg{

					*{
						fill: darken($green, 7.5%);
					}
				}
			}

			svg{
				width: 1.871rem;

				*{
					fill: $black;
				}
			}
		}

		::-webkit-input-placeholder {
			color: #6C6C6B;
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			color: #6C6C6B;
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			color: #6C6C6B;
		}
		
		:-ms-input-placeholder {
			color: #6C6C6B;
		}
	}
}

.new{
	background-color: $green;
	border-radius: .8rem;
	padding: .4rem .8rem;
	display: inline-block;
	@include font-size(16);
	font-weight: 500;
	margin-bottom: 3.1rem;

	@media (max-width: $md){
		border-radius: .4rem;
		@include font-size(14);
		padding: .45rem .8rem;
		margin-bottom: 1.5rem;
	}
}

.cats{
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	margin-bottom: .7rem;

	span{
		display: inline-flex;
		align-items: center;
		flex-wrap: wrap;
		text-transform: uppercase;
		@include font-size(12);

		@media (max-width: $md){
			@include font-size(10);
		}

		&:has(+ span){
			margin-right: 1.6rem;
		}

		i{
			display: block;
			width: 1.2rem;
			height: 1.2rem;
			border-radius: 1.2rem;
			margin-right: .8rem;
		}
	}
}

.n-item{
	margin-bottom: 5rem;

	@media (max-width: $md){
		margin-bottom: 3.2rem;
	}

	@media (min-width: $md + 1){
		
		&:not(.col-md-4):not(.other){

			.inner{
				display: flex;
				align-items: center;

				.img-con{
					aspect-ratio: 628/451;
					width: 49%;
				}

				.text{
					padding: 2.6rem 14rem 2.6rem 3.3rem;
					width: 51%;
				}
			}
		}
	}

	.inner{
		position: relative;

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;
			border-radius: .8rem;

			&:hover,
			&:focus-visible{
				
				~ .text{

					h4{
						color: darken($green, 7.5%);
					}
				}
			}
		}

		.img-con{
			aspect-ratio: 416/306;
			width: 100%;
			border-radius: .8rem;
			background-color: rgba($white, .82);

			@media (max-width: $md){
				aspect-ratio: 343/311;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.text{
			padding: 2.6rem 0rem 3.2rem .8rem;
			width: 100%;

			@media (max-width: $md){
				padding: 3.1rem 0rem 3.2rem .8rem;
			}

			.new{
				text-transform: uppercase;
			}

			h4{
				transition: $time;

				@media (max-width: $md){
					margin-bottom: .75em;
				}
			}
		}
	}
}

.news-archive-section{

	.row{
		padding-bottom: 12.8rem;

		@media (max-width: $md){
			padding-bottom: 6.4rem;
		}
	}
}

.insights-hero-section{

	.container{
		max-width: 88rem;
	}

	.row{
		padding-top: 20.5rem;
		padding-bottom: 3.2rem;

		@media (max-width: $md){
			padding-top: 15.4rem;
			padding-bottom: 3.1
			rem;
		}
	}

	.social-menu{

		@media (max-width: $md){
			margin-top: -.5rem;
		}
	}

	.title-con{
		margin-top: .6rem;
		margin-bottom: 2.6rem;

		@media (max-width: $md){
			margin-top: 1.8rem;
		}

		h1{
			@include font-size(48);
			@include line-height(48,52);
			margin-bottom: .7em;
			text-transform: none;
			font-family: $main-font;

			@media (max-width: $md){
				@include font-size(28);
				@include line-height(28,32);
				margin-bottom: .9em;
			}
		}
	}

	.info-con{
		display: flex;
		align-items: center;
		flex-wrap: wrap;

		.img-con{
			width: 5.6rem;
			margin-right: 1.6rem;

			img{
				width: 5.6rem;
				height: 5.6rem;
				border-radius: 5.6rem;
				aspect-ratio: 1/1;
				object-fit: cover;
				object-position: 50% 50%;
			}
		}

		.info{
			@include font-size(16);
			@include line-height(16,26);

			@media (max-width: $md){
				@include font-size(14);
				@include line-height(14,20);
			}
			
			.name{
				font-weight: 500;
			}
		}
	}
}

.insights-hero-section + .video-section{

	.row{
		padding-top: 0;
	}
}

.video-section{

	&:has(.text-con){

		.container{
			max-width: 108rem;
		}

		.row{
			padding-top: 12.7rem;
			padding-bottom: 4.1rem;

			@media (max-width: $md){
				padding-top: 6.2rem;
			}
		}

		img{

			@media (max-width: $md){
				aspect-ratio: 343.11/193;
			}
		}
	}

	.container{
		max-width: 89.6rem;
	}

	.row{
		padding-top: 3.2rem;
		padding-bottom: 3.2rem;
	}

	.text-con{
		text-align: center;
		margin-bottom: 3.4rem;

		@media (max-width: $md){
			margin-bottom: 3.3rem;
		}
	}

	img{
		border-radius: .8rem;
		width: 100%;

		@media (max-width: $md){
			aspect-ratio: 343/311;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	a{
		position: relative;
		border-radius: .8rem;

		&:hover,
		&:focus-visible{
			
			i{

				&::before{
					background-color: darken($green, 7.5%);
				}
			}
		}

		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%, -50%);
			width: 11.2rem;
			height: 11.2rem;
			border-radius: 11.2rem;
			overflow: hidden;

			@media (max-width: $md){
				width: 7.2rem;
				height: 7.2rem;
			}

			&::before{
				content: '';
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 150%;
				height: 150%;
				background-color: rgba($white, .4);
				filter: blur(.1rem);
				transition: $time;
			}

			&::after{
				content: '';
				position: absolute;
				top: 50%;
				left: 54%;
				transform: translate(-50%, -50%);
				background-image: url(../images/play.svg);
				background-repeat: no-repeat;
				width: 3.3rem;
				aspect-ratio: 33/38;
				background-size: 100% auto;

				@media (max-width: $md){
					left: 56%;
					width: 2.8rem;
				}
			}
		}
	}
}

.image-section{

	.container{
		max-width: 108rem;
	}

	.row{
		padding-top: 12.7rem;
		padding-bottom: 4rem;

		@media (max-width: $md){
			padding-top: 9.3rem;
		}
	}

	.text-con{
		text-align: center;
		margin-bottom: 3.4rem;
	}

	img{
		border-radius: .8rem;
		width: 100%;
	}
}

.half-blocks-section + .simple-text-section,
.video-section + .simple-text-section{

	.row{
		padding-top: 1.6rem;
	}
}

.quote-section + .simple-text-section{

	.row{
		padding-top: 3.4rem;
	}
}

.text-hero-section.slim + .simple-text-section{

	.container{
		max-width: 86.5rem;
	}

	.row{
		padding-top: 1.5rem;
		padding-bottom: 14.2rem;

		@media (max-width: $md){
			padding-bottom: 8rem;
		}
	}

	p{
			
		a{
			
			@media (max-width: $md){
				
				&:first-of-type:last-of-type{
					@include font-size(18);
				}
			}
		}
	}
}


.video-section:has(.text-con) + .simple-text-section,
.image-section + .simple-text-section{

	.container{
		max-width: 106.4rem;
	}

	.row{
		padding-top: 0;
	}

	p{

		a{
			font-weight: 500;

			&:first-of-type:last-of-type{
				@include font-size(14);
			}
		}
	}
}

.simple-text-section{

	.single &{

		.container{
			max-width: 86.5rem;
		}
	}

	.row{
		padding-top: 4.7rem;
		padding-bottom: 4.7rem;
	}

	h4{
		margin-bottom: .78em;

		@media (max-width: $md){
			margin-bottom: 1.08em;
		}
	}
	
	* + h5{
		margin-top: 2.5em;

		@media (max-width: $md){
			margin-top: 3.6em;
		}
	}

	p{
		margin-bottom: 1.46em;
	}
}

.images-section{

	.single &{
		margin-bottom: -.6rem;

		@media (max-width: $md){
			margin-bottom: .2rem;
		}

		.row{
			padding-top: 0rem;
			padding-bottom: 0rem;
		}
	}

	.container{
		max-width: 88.8rem;
	}

	@media (min-width: $md + 1){
		
		.container,
		.col-12{
			padding-right: .4rem;
			padding-left: .4rem;
		}

		.row {
			margin-right: -.4rem;
			margin-left: -.4rem;
		}
	}

	.col-12{
		margin-bottom: .8rem;
	}

	a{
		border-radius: .8rem;
		width: 100%;

		@media (max-width: $md){
			aspect-ratio: 343/311;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	img{
		border-radius: .8rem;
		width: 100%;

		@media (max-width: $md){
			aspect-ratio: 343/311;
			object-fit: cover;
			object-position: 50% 50%;
		}
	}

	.row{
		padding-top: 4.7rem;
		padding-bottom: 4.7rem;
	}
}

[data-fancybox-play],
[data-fancybox-thumbs],
[data-fancybox-zoom]{
	display: none!important;
}

.fancybox-bg.fancybox-bg{
	background-color: rgba($black, .75);
	opacity: 1;
}

.fancybox-image{
	border-radius: .8rem;
}

.other-insights-section{

	.row{
		padding-top: 13.4rem;
		padding-bottom: 20.9rem;

		@media (max-width: $md){
			padding-top: 6.4rem;
			padding-bottom: 14rem;
		}
	}

	.text-con{
		margin-bottom: 6.4rem;

		@media (max-width: $md){
			margin-bottom: 4.8rem;
			text-align: center;
		}
	}

	.consultant-slide{

		.inner-slide.inner-slide{
			width: calc(100% + .8rem + .8rem);
			max-width: calc(100% + .8rem + .8rem);
			margin: 0 -.8rem;
			display: flex;
			flex-wrap: wrap;
		}
	}

	.n-item{
		width: 33.3333%;

		@media (min-width: $md + 1){
			margin-bottom: 0;
		}
		
		@media (max-width: $md){
			width: 100%;
		}

		.inner{
			padding: 0 .8rem;

			.img-con{
				margin-bottom: .4rem;
			}
		}
	}
}

.form-section{
	overflow: visible!important;
	position: relative;
	z-index: 2;

	&:after{
		content: '';
		width: 26.35rem;
		aspect-ratio: 1/1;
		position: absolute;
		bottom: 10.7rem;
		right: -.9rem;
		transform: translate(50%, 50%);
		animation: bubble-big 18s 0s infinite linear;
		background-image: url(../images/bubble.png);
		background-repeat: no-repeat;
		background-size: 100% auto;

		@media (max-width: $md){
			display: none;
		}
	}

	.row{
		min-height: 25rem;
	}

	&.green,
	&.light,
	&.medium-light{

		.col-12.col-12.col-12{

			::-webkit-input-placeholder {
				color: rgba($colour, .64);
			}
			 
			:-moz-placeholder { /* Firefox 18- */
				color: rgba($colour, .64);
			}
			
			::-moz-placeholder {  /* Firefox 19+ */
				color: rgba($colour, .64);
			}
			
			:-ms-input-placeholder {
				color: rgba($colour, .64);
			}

			legend, 
			label{
				color: $black;
			}

			input,
			textarea,
			.fake-select,
			select{
				background-color: transparent;
				color: rgba($colour, .64);
				border-color: $black;
			}

			select{
				background-image: url(../images/select-icon.svg);

				option{
					color: $black;
				}
			}

			.gform_confirmation_message{
				color: darken($green, 7.5%);
			}
		}
	}

	&.green{

		.wpcf7-not-valid.wpcf7-not-valid.wpcf7-not-valid:focus-visible,
		input:focus-visible,
		textarea:focus-visible,
		select:focus-visible,
		input[name][name][name]:focus-visible,
		textarea[name][name][name]:focus-visible,
		select[name][name][name]:focus-visible{
			border-color: darken($green, 7.5%)!important;
		}

		.col-12.col-12.col-12{

			.gform_confirmation_message{
				color: $black;
			}
		}
	}

	.container{
		max-width: 68rem;
	}

	.row{
		padding-top: 4.5rem;
		padding-bottom: 4.5rem;

		@media (max-width: $md){
			padding-top: .3rem;
			padding-bottom: 2.5rem;
		}
	}

	.col-12.col-12.col-12{

		::-webkit-input-placeholder {
			color: rgba($white, .32);
		}
		 
		:-moz-placeholder { /* Firefox 18- */
			color: rgba($white, .32);
		}
		
		::-moz-placeholder {  /* Firefox 19+ */
			color: rgba($white, .32);
		}
		
		:-ms-input-placeholder {
			color: rgba($white, .32);
		}

		input,
		textarea,
		.fake-select,
		select{
			background-color: transparent;
			color: rgba($white, .32);
		}

		select{
			background-image: url(../images/select-icon-light.svg);

			option{
				color: $black;
			}
		}

		.gform_wrapper .gfield_validation_message{
			color: $red;
		}

		.gform_confirmation_message{
			color: $green;
		}
	}
}

.accordion-section{

	&:has(+ .accordion-section){

		.row{
			padding-bottom: 8.1rem;

			@media (max-width: $md){
				padding-bottom: 6.3rem;
			}
		}
	}

	.container{
		max-width: 89.6rem;
	}

	.row{
		padding-top: 3.2rem;
		padding-bottom: 31.3rem;

		@media (max-width: $md){
			padding-top: 0rem;
			padding-bottom: 12.7rem;
		}
	}

	.text-con{
		margin-bottom: 4rem;

		@media (max-width: $md){
			margin-bottom: 3.1rem;
		}
	}

	dd{

		@media (max-width: $md){
			
			p,
			li{
				@include font-size(18);
				@include line-height(18,26);
			}
		}
	}
}

dl.accordion.accordion{

	*:not(em){
		color: $colour;
	}

	dt{
		background-color: $white;
		border-radius: .8rem;
		padding: 3.35rem 7rem 3.35rem 3.2rem;
		@include font-size(20);
		@include line-height(20,28);
		font-weight: 500;
		position: relative;
		cursor: pointer;
		transition: all 0s 0s, color $time 0s;

		@media (max-width: $md){
			padding: 2.45rem 6.2rem 2.45rem 2.4rem;
			@include font-size(18);
			@include line-height(18,24);
		}

		&:hover,
		&:focus-visible{
			color: darken($green, 7.5%);
		}

		&.open{

			i{	
				&::after{
					transform: translate(-50%, -50%) rotate(180deg);
				}
			}

			+ dd{
				max-height: 9999px;
			}
		}
		
		i{
			width: 2.1rem;
			height: 2.1rem;
			position: absolute;
			top: 50%;
			right: 3.75rem;
			transform: translateY(-50%);

			@media (max-width: $md){
				right: 3rem;
			}
	
			&::before,
			&::after{
				content: '';
				width: 2.1rem;
				height: .2rem;
				background-color: $black;
				position: absolute;
				left: 50%;
				top: 50%;
				transform: translate(-50%, -50%) rotate(0deg);
				transition: $time;
			}
	
			&::after{
				transform: translate(-50%, -50%) rotate(90deg);
			}
		}

		&.open{
			border-radius: .8rem .8rem 0 0;
		}
	}

	dd{
		background-color: $white;
		max-height: 0;
		overflow: hidden;
		margin: 0 0 1.6rem;
		transition: $time;
		border-radius: 0 0 .8rem .8rem;
		
		.inner{
			padding: .15rem 3.2rem 3.25rem 3.2rem;

			@media (max-width: $md){
				padding: .8rem 2.3rem 2.25rem 2.4rem;
			}
		}
	}
}

.locations-section{

	.row{
		padding-top: 6.4rem;
		padding-bottom: 8.4rem;

		@media (max-width: $md){
			padding-top: 4.8rem;
			padding-bottom: 5.5rem;
		}
	}

	&.orange,
	&.purple,
	&.dark,
	&.medium-dark{

		dl.accordion.accordion dt{
			color: $white;

			i{

				&::before,
				&::after{
					background-color: $white;
				}
			}
		}
	}

	dl.accordion.accordion{

		dt{
			background-color: transparent;
			border-radius: 0;
			border-bottom: .1rem solid rgba($green, .32);
			color: $colour;
			@include font-size(48);
			@include line-height(48,52);
			font-weight: 600;
			padding: 3.35rem 7rem 3.35rem 0rem;

			@media (max-width: $md){
				@include font-size(28);
				@include line-height(28,32);
				padding: 2.45rem 4rem 2.45rem 0rem;
			}

			&.open{
				border-radius: 0;
			}

			i{
				right: .6rem;
			}
		}

		dd{
			background-color: transparent;
			margin-bottom: 2.8rem;

			@media (max-width: $md){
				margin-bottom: 1.7rem;
			}

			.inner{
				display: flex;
				flex-wrap: wrap;
				padding: 4.7rem 0 0;
				margin-left: -.8rem;
				width: calc(100% + .8rem + .8rem);
				max-width: calc(100% + .8rem + .8rem);

				@media (max-width: $md){
					padding: 3.2rem 0 0;
				}
			}

			.address{
				width: 25%;
				padding: 0 .8rem;
				margin-bottom: 4.8rem;
				display: flex;

				@media (min-width: $md + 1){
					
					&:nth-last-of-type(4),
					&:nth-last-of-type(3),
					&:nth-last-of-type(2),
					&:nth-last-of-type(1){
						margin-bottom: 0;
					}
				}

				@media (max-width: $md){
					width: 100%;
					margin-bottom: 1.5rem;
				}

				.inner-inner{
					border-radius: .8rem;
					background-color: $white;
					padding: 4.8rem 3.2rem;
					width: 100%;

					h4{
						margin-bottom: .78em;

						@media (max-width: $md){
							margin-bottom: 1.1em;
						}
					}
				}
			}
		}
	}
}

.four-zero-four-section{
	position: relative;

	.row{
		padding-top: 36.2rem;
		padding-bottom: 27.4rem;
		text-align: center;

		@media (max-width: $md){
			padding-top: 31.1rem;
			padding-bottom: 33.2rem;
		}
	}

	h1{
		@include font-size(64);
		@include line-height(64,70);
		margin-bottom: .56em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,44);
			margin-bottom: .65em;
		}
	}

	.col-12{

		@media (max-width: $md){
			padding-left: 1.6rem;
			padding-right: 1.6rem;

			p{
				padding-left: 3rem;
				padding-right: 3rem;
			}
		}
	}

	.bubble-big,
	.bubble-small{
		width: 54.9rem;
		position: absolute;
		bottom: 2.7rem;
		right: 13.1rem;
		transform: translate(50%, 50%);

		@media (max-width: $md){
			width: 37.806rem;
			bottom: 4.5rem;
			right: 11.1rem;
		}
	}

	.bubble-big{
		animation: bubble-big 18s 0s infinite linear;

		img{
			animation: bubble-big-img 8s 0s infinite linear;
		}
	}

	.bubble-small{
		width: 26.35rem;
		bottom: auto;
		top: 5.2rem;
		right: auto;
		left: -9.1rem;
		animation: bubble-small 20s 0s infinite linear;

		@media (max-width: $md){
			width: 15.887rem;
			top: 5rem;
			left: -16.4rem;
		}

		img{
			animation: bubble-small-img 9s 0s infinite linear;
		}
	}
}

.directors-archive-section{

	.row{
		padding-top: 6.3rem;
		padding-bottom: 12.7rem;

		@media (max-width: $md){
			padding-top: 3.2rem;
		}
	}
}

.campaigns-archive-section{

	.container{
		max-width: 89.6rem;
	}

	.row{
		padding-bottom: 12.7rem;

		@media (max-width: $md){
			padding-bottom: 6.4rem;
		}
	}
}

.cam-item{
	margin-bottom: 3.3rem;

	@media (max-width: $md){
		margin-bottom: 1.8rem;
	}

	.inner{

		a.video{
			position: relative;
			border-radius: .8rem;
			display: block;
			width: 100%;
	
			&:hover,
			&:focus-visible{
				
				i{
	
					&::before{
						background-color: darken($green, 7.5%);
					}
				}
			}
	
			i{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
				width: 11.2rem;
				height: 11.2rem;
				border-radius: 11.2rem;
				overflow: hidden;
				z-index: 1;

				@media (max-width: $md){
					width: 7.2rem;
					height: 7.2rem;
				}
	
				&::before{
					content: '';
					position: absolute;
					top: 50%;
					left: 50%;
					transform: translate(-50%, -50%);
					width: 150%;
					height: 150%;
					background-color: rgba($white, .4);
					filter: blur(.1rem);
					transition: $time;
				}
	
				&::after{
					content: '';
					position: absolute;
					top: 50%;
					left: 54%;
					transform: translate(-50%, -50%);
					background-image: url(../images/play.svg);
					background-repeat: no-repeat;
					width: 3.3rem;
					aspect-ratio: 33/38;
					background-size: 100% auto;

					@media (max-width: $md){
						left: 56%;
						width: 2.8rem;
					}
				}
			}
		}

		.img-con{
			aspect-ratio: 880/495;
			border-radius: .8rem;
		}

		.text{
			padding: 3.9rem .8rem;

			@media (max-width: $md){
				padding: 3.1rem 0rem;
			}
		}

		.cats{
			margin-bottom: .6rem;

			span{
				letter-spacing: .05em;
				opacity: .2;
				font-weight: 500;
			}
		}

		h4{
			margin-bottom: .55em;

			@media (max-width: $md){
				margin-bottom: .7em;
			}
		}

		p{
			margin-bottom: 1.7em;

			@media (max-width: $md){
				margin-bottom: 2.7em;
			}
		}

		a.read{
			font-weight: 600;

			@media (max-width: $md){
				@include font-size(14);
			}
		}
	}
}

.regulatory-news-archive-section,
.reports-archive-section{

	.row{
		padding-bottom: 12.9rem;

		@media (max-width: $md){
			padding-bottom: 6.4rem;
		}
	}

	.pagination{
		margin-top: 3.1rem;

		@media (max-width: $md){
			margin-top: .8rem;
		}
	}
}

.s-item,
.r-item{
	margin-bottom: 3.2rem;

	@media (max-width: $md){
		margin-bottom: 2.4rem;
	}

	.inner{
		padding: 3.2rem 6.2rem 3.2rem 3.2rem;
		background-color: $white;
		border-radius: .8rem;
		position: relative;
		transition: $time;

		@media (max-width: $md){
			padding: 3.2rem 3.2rem 8.1rem 3.2rem;
		}

		&:hover,
		&:focus-visible{
			background-color: darken($green, 7.5%);
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 1;

			// &:hover,
			// &:focus-visible{
				
			// 	svg{

			// 		*{
			// 			fill: darken($green, 7.5%);
			// 		}
			// 	}

			// 	~ .text{

			// 		h4{
			// 			color: darken($green, 7.5%);
			// 		}
			// 	}
			// }

			svg{
				position: absolute;
				top: 50%;
				right: 3.45rem;
				transform: translateY(-50%);
				width: 2.62rem;
				aspect-ratio: 26.2/24.28;

				@media (max-width: $md){
					top: auto;
					bottom: 2.4rem;
					right: auto;
					left: 3.45rem;
				}

				*{
					transition: $time;
				}
			}
		}
	}

	.text{
		width: 84rem;
	}

	p{
		margin-bottom: 1.19em;

		@media (max-width: $md){
			display: -webkit-box;
			-webkit-line-clamp: 3;
			-webkit-box-orient: vertical;
			overflow: hidden;
			margin-bottom: 1em;
		}
	}

	h4{
		margin-bottom: .55em;
		display: flex;
		align-items: center;
		transition: $time;

		@media (max-width: $md){
			flex-direction: column-reverse;
			align-items: flex-start;
			margin-bottom: .75em;
		}

		.new{
			margin-left: 1.3rem;
			text-transform: uppercase;
			color: $colour!important;

			@media (max-width: $md){
				margin-left: 0;
				padding: .55rem .8rem;
				margin-bottom: 1.6rem!important;
			}
		}

		.plus,
		.minus{
			background-color: $green;
			display: inline-flex;
			align-items: center;
			@include font-size(16);
			border-radius: .8rem;
			padding: .5rem .6rem;
			font-weight: 500;
			text-align: left;
			margin-left: 1.6rem;

			@media (max-width: $md){
				@include font-size(14);
				border-radius: .4rem;
				padding: .6rem .7rem;
				margin-left: 0;
				margin-bottom: 1.6rem!important;
			}

			svg{
				width: .9121rem;
				aspect-ratio: 9.121/5.311;
				margin-left: .7rem;
				margin-right: .2rem;

				@media (max-width: $md){
					margin-right: .4rem;
				}
			}
		}

		.plus{
			color: $colour!important;
		}

		.minus{
			background-color: $red;
			color: white!important;
		}
	}
}

.s-item{
	margin-bottom: 0;

	.inner{
		background-color: transparent;
		border-radius: 0;
		border-top: .1rem solid rgba($black, .32);
		padding: 4.7rem .8rem;

		@media (max-width: $md){
			padding: 3.2rem 0rem;
		}
	}

	.text{
		width: 100%;

		p{

			@media (max-width: $md){
				-webkit-line-clamp: 2;
				margin-bottom: 1.85em;
			}
		}
	}
}

.search-top-section{

	.container{
		max-width: 67.8rem;
	}

	.row{
		padding-top: 19.6rem;
		padding-bottom: 11.1rem;

		@media (max-width: $md){
			padding-top: 14.2rem;
			padding-bottom: 4rem;
		}
	}
	
	.text{
		text-align: center;
		margin-bottom: 3.75rem;

		@media (max-width: $md){
			margin-bottom: 2.25rem;
		}
	}

	h1{
		@include font-size(64);
		@include line-height(64,70);
		margin-bottom: .56em;

		@media (max-width: $md){
			@include font-size(40);
			@include line-height(40,44);
			padding-bottom: .65em;
			border-bottom: .1rem solid rgba($black, .32);
		}
	}

	.search-con{
		position: relative;
		width: calc(100% - 14.1rem);

		@media (max-width: $md){
			width: 100%;
			margin-bottom: 1.7rem;
		}

		svg{
			width: 1.871rem;
			position: absolute;
			bottom: 2.8rem;
			right: 1.8rem;
			transform: translateY(50%);

			*{
				fill: $black;
			}
		}

		label{
			color: $black;

			@media (min-width: $md + 1){
				display: none;
			}
		}
	}


	[type="search"]{
		background-color: transparent;
		border: .1rem solid $black;
		padding-right: 5rem;
		width: 100%;
	}

	[type="submit"]{
		background-color: $black;
		border-color: $black;
		color: $white!important;
		margin-left: 1.6rem;
		width: 12.5rem;
		padding: 1.912rem .5rem;

		@media (max-width: $md){
			width: 100%;
			margin-left: 0;
			font-size: 1.8rem!important;
			padding: 1.312rem 0.5rem;
		}
		
		&:hover,
		&:focus-visible{
			background-color: $green;
			border-color: $green;
			color: $black!important;
		}
	}

	form{
		display: flex;
		align-items: center;
		flex-wrap: wrap;
	}
}

.selected-popular-searches-section{

	.row{
		padding-bottom: 12.9rem;

		@media (max-width: $md){
			padding-bottom: 22.9rem;
		}
	}

	.col-12{
		
		.inner{
			background-color: $white;
			border-radius: .8rem;
			padding: 3.2rem 3.2rem 3.4rem;

			@media (max-width: $md){
				padding: 3.2rem 1.7rem 3.6rem;
			}
		}

		h4{
			margin-bottom: 1.05em;

			@media (max-width: $md){
				margin-bottom: 1.62em;
			}
		}
	}

	.po-item{
		position: relative;
		display: flex;
		align-items: center;
		margin-bottom: 2rem;

		@media (max-width: $md){
			margin-bottom: 2.4rem;
		}

		a{
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			z-index: 2;

			&:hover,
			&:focus-visible{
				
				~ h5{
					color: darken($green, 7.5%);
				}

				~ svg{

					*{
						fill: darken($green, 7.5%);
					}
				}
			}
		}

		svg{
			width: 2.9146rem;
			aspect-ratio: 29.146/17.282;
			margin-right: 1.3rem;

			*{
				fill: $black;
				transition: $time;
			}
		}

		h5{
			transition: $time;
		}
	}
}

.results-section{

	&:has(.pagination){

		.all-results{
			display: none;
		}
	}

	&:not(:has(.pagination)){

		.showing{
			display: none;
		}
	}

	.row{
		padding-bottom: 11.2rem;

		@media (max-width: $md){
			padding-top: 2.4rem;
			padding-bottom: 6.3rem;
		}
	}

	.results-top-con{
		margin-bottom: 8.8rem;

		@media (max-width: $md){
			margin-bottom: 6.4rem;
		}
	}

	.results-con{
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 1.7rem;
		margin-bottom: 3.2rem;
		flex-wrap: wrap;

		.text{
			width: 100%;
		}

		*{
			font-weight: 500;
		}

		.all-results{
			@include font-size(20);
			@include line-height(20,28);

			@media (max-width: $md){
				@include font-size(18);
				@include line-height(18,24);
			}
		}

		.showing{
			margin-left: auto;
		}
	}

	.pagination{

		@media (max-width: $md){
			margin-top: 3.3rem;
		}
	}
}





























.otgs-development-site-front-end{
	display: none!important;
}





//
//!!BLOCKS END
//

@media (min-width: $md + 1){
	
	.container{
		max-width: ($base-widthWO + $grid-gutter-widthWO) / 10 + rem;
	}

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: $grid-gutter-widthWO / 2 / 10 + rem;
		padding-left: $grid-gutter-widthWO / 2 / 10 + rem;
	}

	.row{
		margin-right: -$grid-gutter-widthWO / 2 / 10 + rem;
		margin-left: -$grid-gutter-widthWO / 2 / 10 + rem;
	}
}

@media (max-width: $md){

	.container,
	.col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col, .col-auto, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm, .col-sm-auto, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md, .col-md-auto, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg, .col-lg-auto, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl, .col-xl-auto{
		padding-right: .8rem;
		padding-left: .8rem;
	}

	.container{
		padding-right: 1.6rem;
		padding-left: 1.6rem;
	}

	.row{
		margin-right: -.8rem;
		margin-left: -.8rem;
	}
}