footer{
	position: relative;

	.footer-top-outer{
		background-color: $grey;

		.container{
			padding-top: 6.4rem;
			padding-bottom: 4rem;

			@media (max-width: $md){
				padding-top: 4.8rem;
				padding-bottom: 3.9rem;
				text-align: center;
			}
		}

		.row{

			@media (min-width: $md + 1){
				justify-content: space-between;
			}
		}

		h6{
			opacity: .42;
			margin-bottom: .95em;

			@media (max-width: $md){
				display: none;
			}
		}

		.col-md-2{
			margin-bottom: 6.4rem;

			@media (min-width: $md + 1){
				flex: 0 0 auto;
				max-width: 15%;
			}

			@media (max-width: $md){
				margin-bottom: 4.4rem;
			}
		}
		
		.footer-menu{
			@include font-size(18);
			@include line-height(18,26);
			margin: 0 0;

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,22);
			}

			li{
				@include font-size(18);
				@include line-height(18,26);
				margin-bottom: 1.2rem;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,22);
				}
			}

			a{
				@include font-size(18);
				@include line-height(18,26);
				font-family: $main-font;
				font-weight: 400;
				text-decoration: none;

				@media (max-width: $md){
					@include font-size(16);
					@include line-height(16,22);
				}

				&:hover,
				&:focus-visible{
					color: darken($green, 7.5%);
				}
			}
		}

		.copy{
			@include font-size(16);
			@include line-height(16,26);

			@media (max-width: $md){
				@include font-size(16);
				@include line-height(16,22);
				margin-top: 2rem;
			}
		}

		#footer-logo{
			display: block;
			height: auto;
			margin: -4.3rem 0 0;
			z-index: 1;
			transition: 0s;
			display: flex;

			@media (max-width: $md){
				margin: 0;
			}
		
			img,
			svg{
				width: 100%;
			}
		}
	}
}